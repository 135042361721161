var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex" }, [
    _vm.customlabel && !_vm.editlock
      ? _c("input", {
          staticClass: "mr-auto inputlabel text-center inputStressBoxLabel",
          class: { "d-none": _vm.labelHidden },
          staticStyle: { width: "30px" },
          attrs: { type: "text", placeholder: _vm.stress.placeholder },
          domProps: { value: _vm.$parent.getVal("" + _vm.stress.label) },
          on: {
            change: function ($event) {
              return _vm.$parent.setVal(
                "" + _vm.stress.label,
                $event.target.value
              )
            },
          },
        })
      : _c(
          "label",
          {
            staticClass: "px-1 pt-1 inputStressBoxLabel",
            class: { "d-none": _vm.labelHidden },
            attrs: { for: _vm.stress.obj },
          },
          [_vm._v(_vm._s(_vm.getLabelValue))]
        ),
    _vm._v(" "),
    _c("input", {
      attrs: {
        type: "checkbox",
        id: _vm.stress.obj,
        name: _vm.stress.obj,
        disabled: !_vm.skillHasValue(),
      },
      domProps: {
        value: _vm.stress.value,
        checked: _vm.$parent.getVal(_vm.stress.obj),
      },
      on: {
        change: function ($event) {
          return _vm.setVal(_vm.stress.obj, $event.target.checked)
        },
      },
    }),
    _vm._v(" "),
    _vm.removable && !_vm.editlock
      ? _c(
          "button",
          {
            staticClass: "btn btn-link text-secondary m-0 p-0",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.removeStressBox(_vm.stress.id, _vm.parentid)
              },
            },
          },
          [
            _c("i", {
              staticClass: "fas d-print-none fa-minus-circle",
              attrs: { title: "Delete Stress Box" },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }