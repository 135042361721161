var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.showlabel
      ? _c("div", { staticClass: "d-flex" }, [
          _vm.customlabel && !_vm.editlock
            ? _c("input", {
                staticClass: "w-100 mr-auto inputlabel inputAspectLabel",
                attrs: { type: "text", placeholder: _vm.aspect.placeholder },
                domProps: { value: _vm.$parent.getVal("" + _vm.aspect.label) },
                on: {
                  change: function ($event) {
                    return _vm.$parent.setVal(
                      "" + _vm.aspect.label,
                      $event.target.value
                    )
                  },
                },
              })
            : _c("label", { staticClass: "inputAspectLabel" }, [
                _vm._v(_vm._s(_vm.getLabelValue)),
              ]),
          _vm._v(" "),
          _vm.removable && !_vm.editlock
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link text-secondary m-0 p-0",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.removeAspect(_vm.aspect.id)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fas d-print-none fa-minus-circle pr-2",
                    attrs: { title: "Delete Aspect" },
                  }),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex" }, [
      _vm.vttEnabled
        ? _c(
            "span",
            {
              staticClass: "dice fo20 pt-2 pr-1",
              on: {
                click: function ($event) {
                  return _vm.sendToVTT(
                    "invoke",
                    "aspect",
                    "aspects",
                    _vm.aspect.obj
                  )
                },
              },
            },
            [_vm._v("C")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control inputAspect",
        attrs: {
          type: "text",
          id: _vm.aspect.obj,
          name: "aspects." + _vm.aspect.obj,
          placeholder: _vm.getPlaceHolder,
        },
        domProps: { value: _vm.$parent.getVal(_vm.aspect.obj) },
        on: {
          change: function ($event) {
            return _vm.$parent.setVal(_vm.aspect.obj, $event.target.value)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }