var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-2" },
    [
      _vm.characterData
        ? _c("charactersheet", {
            attrs: {
              character: _vm.characterData,
              sheetid: _vm.characterData.related_id,
              isOwner: _vm.isOwner,
            },
            on: { "save-character": _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-print-none" },
        [
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _vm.isAuthenticated && _vm.isOwner
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success mr-1",
                    attrs: { type: "button" },
                    on: { click: _vm.save },
                  },
                  [
                    _vm._v("Save Character "),
                    _c("i", { staticClass: "fa fa-user" }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-dark",
                attrs: { type: "button" },
                on: { click: _vm.print },
              },
              [
                _vm._v("Print Character "),
                _c("i", { staticClass: "fa fa-print" }),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.characterData
            ? _c("characterprops", {
                staticClass: "pt-1",
                attrs: {
                  characterData: _vm.characterData,
                  isCustomizable: _vm.IsCustomizableSheet,
                  isOwner: _vm.isOwner,
                },
                on: { "save-character": _vm.save },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-secondary d-print-none mr-1",
        attrs: { href: "/character", role: "button" },
      },
      [_vm._v("Close "), _c("i", { staticClass: "fa fa-times-circle" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }