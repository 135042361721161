var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "fate-header d-flex",
        style: { backgroundColor: _vm.headerColor ? _vm.headerColor : "" },
      },
      [
        _c("span", { staticClass: "mr-auto", attrs: { for: _vm.item } }, [
          _vm._v(_vm._s(_vm.header)),
        ]),
        _vm._v(" "),
        !_vm.isNewCharacter
          ? _c("a", [
              _c("i", {
                staticClass: "fas d-print-none pr-2",
                class: { "fa-plus": _vm.stuntEdit, "d-none": !_vm.stuntEdit },
                on: {
                  click: function ($event) {
                    return _vm.addStunt(_vm.item)
                  },
                },
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "fas d-print-none pr-2",
                class: {
                  "fa-check-circle": _vm.stuntEdit,
                  "fa-edit": !_vm.stuntEdit,
                },
                on: {
                  click: function ($event) {
                    return _vm.toggleEdit()
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isNewCharacter
          ? _c("div", [
              _c("i", {
                staticClass: "fas d-print-none fa-plus pr-2",
                staticStyle: { cursor: "pointer" },
                attrs: { title: "Add Stunt/Extra" },
                on: {
                  click: function ($event) {
                    return _vm.addStunt(_vm.item)
                  },
                },
              }),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group stuntText" },
      [
        _vm.stuntEdit || _vm.isNewCharacter
          ? _c("textarea", {
              staticClass: "form-control",
              attrs: {
                id: _vm.item,
                name: _vm.item,
                rows: _vm.rows,
                placeholder: _vm.header,
              },
              domProps: { value: _vm.$parent.getVal(_vm.item) },
              on: {
                change: function ($event) {
                  return _vm.$parent.setVal(_vm.item, $event.target.value)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.stuntEdit && !_vm.isNewCharacter
          ? _c("VueShowdown", {
              staticClass: "h-auto p-2",
              class: { border: _vm.border },
              style: { "min-height": _vm.minHeight + "px" },
              attrs: {
                options: _vm.vueShowdownOpts,
                markdown: _vm.getMarkupVal(_vm.item),
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }