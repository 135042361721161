var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "input-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.$store.state.searchText,
            expression: "$store.state.searchText",
          },
        ],
        staticClass: "form-control",
        attrs: { id: "search-text", type: "text", placeholder: "Search" },
        domProps: { value: _vm.$store.state.searchText },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.$store.state, "searchText", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-secondary",
            attrs: { type: "button" },
            on: { click: _vm.clearSearch },
          },
          [_c("i", { staticClass: "fa fa-times-circle" })]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "searchbutton",
            staticClass: "btn btn-outline-success d-none",
            attrs: { id: "search-button", type: "button" },
            on: { click: _vm.search },
          },
          [_vm._v("Search")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }