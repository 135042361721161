var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mt-2 text-center" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row m-4 justify-content-md-center" }, [
      _c("div", { staticClass: "col-sm-12 col-md-4" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary col-sm-12 mt-1 mb-1",
            attrs: { type: "button" },
            on: { click: _vm.confirm },
          },
          [
            _vm._v("\n          Update Password "),
            _c("i", { staticClass: "fas fa-sign-in-alt" }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 h1" }, [
        _vm._v("\n      Password Confirmation\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 h4" }, [
        _vm._v(
          "\n      Confirm your new password by entering your new password and the confirmation code sent to your email address.\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "password",
          id: "password",
          "aria-describedby": "emailHelp",
          placeholder: "Enter new password",
        },
      }),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "confirmationcode",
          id: "confirmationcode",
          "aria-describedby": "emailHelp",
          placeholder: "Enter confirmation code",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }