var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("input", {
      staticClass: "form-control text-center pr-md-0 pl-3",
      class: _vm.inputclass,
      attrs: {
        type: "number",
        id: "fatepoints",
        name: "fatepoints",
        placeholder: _vm.getPlaceHolder,
      },
      domProps: { value: _vm.$parent.getVal("fatepoints") },
      on: {
        change: function ($event) {
          return _vm.setVal("fatepoints", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }