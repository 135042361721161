var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-2" },
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", [
            _vm.isAuthenticated
              ? _c(
                  "div",
                  { staticClass: "d-print-none mb-2 hide-on-detail d-md-flex" },
                  [_vm._m(0), _vm._v(" "), _c("search", {})],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.hasCharacters
              ? _c("div", [
                  _vm.searchText == ""
                    ? _c("h2", [_vm._v("You have not created any characters.")])
                    : _c("h2", [_vm._v("No characters found.")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasCharacters
              ? _c(
                  "div",
                  { staticClass: "card-columns" },
                  _vm._l(_vm.characters, function (item, index) {
                    return _c("div", { key: item.id, staticClass: "card" }, [
                      item.image_url
                        ? _c("img", {
                            staticClass: "card-img-top list-image",
                            attrs: {
                              src: item.image_url,
                              alt: "character image",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _c("h5", { staticClass: "card-title character-name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("p", { staticClass: "card-text col" }, [
                            _c("label", { staticClass: "h6" }, [
                              _vm._v("High Concept"),
                            ]),
                            _vm._v(
                              ": " + _vm._s(_vm.getAspect(item, "highconcept"))
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("label", { staticClass: "h6" }, [
                              _vm._v("Trouble"),
                            ]),
                            _vm._v(
                              ": " +
                                _vm._s(_vm.getAspect(item, "trouble")) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                href: _vm.slugify[index],
                                "data-id": item.id,
                              },
                            },
                            [
                              _vm._v("Play "),
                              _c("i", { staticClass: "fa fa-play-circle" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-info ml-1",
                              attrs: { href: _vm.slugify[index] },
                              on: { click: _vm.shareUrl },
                            },
                            [
                              _vm._v("Share "),
                              _c("i", { staticClass: "fa fa-share-square" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-secondary ml-1 mr-auto",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyCharacter($event, item.id)
                                },
                              },
                            },
                            [
                              _vm._v("Copy "),
                              _c("i", { staticClass: "fas fa-copy" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              staticStyle: { color: "red" },
                              attrs: {
                                href: "#",
                                title: "Delete Character",
                                "data-id": item.id,
                                "data-toggle": "modal",
                                "data-target": "#modalDeleteCharacterConfirm",
                              },
                            },
                            [_c("i", { staticClass: "fa fa-trash" })]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-footer text-muted" }, [
                        item.description
                          ? _c("div", { staticClass: "small" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.getShortText(item.description)) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "badge badge-dark",
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  "data-search-text": _vm.commonSvc.GetId(
                                    item.related_id
                                  ),
                                },
                                on: { click: _vm.searchByTag },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.commonSvc.GetId(item.related_id)
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(item.tags, function (tag) {
                              return _c(
                                "span",
                                {
                                  key: tag.text,
                                  staticClass: "badge badge-secondary mr-1",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { "data-search-text": tag.text },
                                  on: { click: _vm.searchByTag },
                                },
                                [
                                  _vm._v(
                                    "                \n              " +
                                      _vm._s(tag.text) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("input", { staticClass: "hidden", attrs: { id: "copyUrl" } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "modalDeleteCharacterConfirm",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "deleteLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-dialog", attrs: { role: "document" } },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _vm._m(2),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger js-delete-character",
                            attrs: { type: "button" },
                            on: { click: _vm.deleteCharacter },
                          },
                          [_vm._v("Delete")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button", "data-dismiss": "modal" },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-success mr-auto mb-1 mb-md-0",
        attrs: { href: "/charactersheet" },
      },
      [_vm._v("Create a Character "), _c("i", { staticClass: "fa fa-user" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "deleteLabel" } }, [
        _vm._v("Confirm Character Delete"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", [_vm._v("Are you sure you want to delete this character?")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }