import { render, staticRenderFns } from "./input-aspect.vue?vue&type=template&id=5208aa4b&scoped=true&"
import script from "./input-aspect.vue?vue&type=script&lang=js&"
export * from "./input-aspect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5208aa4b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fcs/fcs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5208aa4b')) {
      api.createRecord('5208aa4b', component.options)
    } else {
      api.reload('5208aa4b', component.options)
    }
    module.hot.accept("./input-aspect.vue?vue&type=template&id=5208aa4b&scoped=true&", function () {
      api.rerender('5208aa4b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/input-aspect.vue"
export default component.exports