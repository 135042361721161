var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modalDiceRoller",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "diceRollerLabel",
        "aria-hidden": "true",
        "data-backdrop": "static",
        "data-keyboard": "false",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.RollDice },
              },
              [_vm._v("Roll")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: { type: "button" },
                on: { click: _vm.ClearDiceTray },
              },
              [_vm._v("Clear")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", "data-dismiss": "modal" },
              },
              [_vm._v("Close")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "diceRollerLabel" } },
        [
          _c("span", { staticClass: "dice" }, [_vm._v("+")]),
          _vm._v(" Fate Dice Roller "),
          _c("span", { staticClass: "dice" }, [_vm._v("-")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row px-0 mx-0 pt-1 pb-1 roll-modifier" }, [
      _c("label", { staticClass: "col-4 h5 pt-2" }, [_vm._v("Roll Modifier")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control col-2 text-center",
        attrs: { type: "number", id: "rollModifier" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }