var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sheet" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col-sm-6 text-center order-md-2 text-md-right pb-2 pb-md-0",
        },
        [
          _c("img", {
            staticClass: "img-fluid fate-logo",
            attrs: { alt: "Fate of Cthulhu", src: _vm.$parent.GetSheetImage() },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "name",
              name: "name",
              placeholder: "Name",
            },
            domProps: { value: _vm.getVal("name") },
            on: {
              change: function ($event) {
                return _vm.setVal("name", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-sm-6 col-md-8 fate-aspects px-0",
          staticStyle: { "border-right": "2px solid #3A5224" },
        },
        [
          _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
            _vm._v("Aspects"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.aspects, function (aspect) {
            return _c(
              "div",
              { key: aspect.obj },
              [_c("inputaspect", { attrs: { aspect: aspect } })],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c(
                "inputstuntextra",
                _vm._g(
                  {
                    attrs: {
                      item: "stunts",
                      rows: 18,
                      border: false,
                      header: "Regular Stunts",
                    },
                  },
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c(
                "inputstuntextra",
                _vm._g(
                  {
                    attrs: {
                      item: "stunts_corrupted",
                      rows: 14,
                      border: false,
                      header: "Corrupted Stunts",
                    },
                  },
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-4 fate-skills px-0" }, [
        _c("div", { staticClass: "fate-header col-12" }, [_vm._v("Vitals")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pl-3" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group text-center font-weight-bold d-flex" },
              [
                _c(
                  "div",
                  { staticClass: "form-group w-50" },
                  [
                    _c("input", {
                      staticClass: "form-control text-center",
                      attrs: {
                        type: "number",
                        id: "refresh",
                        name: "refresh",
                        placeholder: "Refresh",
                      },
                      domProps: { value: _vm.getVal("refresh") },
                      on: {
                        change: function ($event) {
                          return _vm.setVal("refresh", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.vttEnabled
                      ? _c("span", { staticClass: "dice fo20" }, [_vm._v("A")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v("FP")]),
                    _vm._v(" "),
                    _c("inputfatepoints", {
                      attrs: { inputclass: "fatepoints", placeholder: "-" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group w-50 d-flex flex-wrap justify-content-center",
                  },
                  _vm._l(_vm.corruption, function (stress) {
                    return _c(
                      "div",
                      { key: stress.obj },
                      [
                        _c("inputstress", {
                          attrs: {
                            stress: stress,
                            stresstype: "Corruption",
                            hidelabel: true,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group text-center font-weight-bold pt-2" },
              [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v("STRESS "),
                  _vm.vttEnabled
                    ? _c(
                        "span",
                        { staticClass: "dice fo20 font-weight-normal" },
                        [_vm._v("D")]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-md-flex flex-row pb-2" }, [
              _c(
                "div",
                { staticClass: "form-group font-weight-bold mr-2 text-right" },
                [
                  _vm._v(
                    "\n                        PHYSICAL\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-between stressbox" },
                _vm._l(_vm.physicalstress, function (stress) {
                  return _c(
                    "div",
                    { key: stress.obj },
                    [
                      _c("inputstress", {
                        attrs: {
                          stress: stress,
                          stresstype: "Physical",
                          hidelabel: true,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-md-flex flex-row" }, [
              _c(
                "div",
                { staticClass: "form-group font-weight-bold mr-1 ml-3" },
                [
                  _vm._v(
                    "\n                        MENTAL\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-between stressbox" },
                _vm._l(_vm.mentalstress, function (stress) {
                  return _c(
                    "div",
                    { key: stress.obj },
                    [
                      _c("inputstress", {
                        attrs: {
                          stress: stress,
                          stresstype: "Mental",
                          hidelabel: true,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group text-center font-weight-bold pt-2" },
              [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v("CONSEQUENCES "),
                  _vm.vttEnabled
                    ? _c(
                        "span",
                        { staticClass: "dice fo20 font-weight-normal" },
                        [_vm._v("D")]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.consequences, function (consequence) {
              return _c(
                "div",
                { key: consequence.obj },
                [
                  _c("inputconsequence", {
                    attrs: { consequence: consequence },
                  }),
                ],
                1
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "fate-header col-12" }, [_vm._v("Skills")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pl-3" },
          _vm._l(_vm.skills, function (skill) {
            return _c(
              "div",
              { key: skill.obj, staticClass: "pt-1" },
              [_c("inputskill", { attrs: { item: skill } })],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "form-group text-center font-weight-bold d-flex flex-row",
      },
      [
        _c("div", { staticClass: "col-6" }, [_vm._v("REFRESH")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [_vm._v("CORRUPTION")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }