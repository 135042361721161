var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mt-2 text-center" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row m-4 justify-content-md-center" }, [
      _c("div", { staticClass: "col-sm-12 col-md-4" }, [
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "small" }, [
          _vm._v(
            "\n        Password must be atleast 8 characters long and contain atleast one number, uppercase letter and lowercase letter.\n      "
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn btn-primary col-sm-12 col-md-5 mt-1 mb-1 mt-2 btn-register",
            attrs: { type: "button" },
            on: { click: _vm.register },
          },
          [
            _vm._v("\n          Register "),
            _c("i", { staticClass: "fas fa-user-plus" }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 h1" }, [
        _vm._v("\n      Register\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12" }, [
        _vm._v(
          "\n      Register your account to get access to unlimited characters and campaigns. After you register, you can subscribe to get additional benefits!\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "email" } }, [_vm._v("Email address")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "email",
          id: "email",
          "aria-describedby": "emailHelp",
          placeholder: "Enter email",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "password" } }, [_vm._v("Password")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "password", id: "password", placeholder: "Password" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "passwordConfirm" } }, [
        _vm._v("Confirm Password"),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "password",
          id: "passwordConfirm",
          placeholder: "Confirm Password",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }