var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.characterData && _vm.isAuthenticated && _vm.isOwner
      ? _c("div", [
          _c(
            "ul",
            {
              staticClass: "nav nav-tabs",
              attrs: { id: "propertyTabs", role: "tablist" },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm.isCustomizable
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item",
                      attrs: { id: "TabSheetProperties" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            id: "sheet-tab",
                            "data-toggle": "tab",
                            href: "#sheetProperties",
                            role: "tab",
                            "aria-controls": "sheet",
                            "aria-selected": "false",
                          },
                        },
                        [_vm._v("Sheet Properties")]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tab-content", attrs: { id: "tabProperties" } },
            [
              _c(
                "div",
                {
                  staticClass: "tab-pane fade show active",
                  attrs: {
                    id: "characterProperties",
                    role: "tabpanel",
                    "aria-labelledby": "character-tab",
                  },
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "d-flex" }, [
                      _vm.hasPortraitUrl
                        ? _c("img", {
                            staticClass: "img-fluid img-thumbnail mr-1",
                            staticStyle: { "max-width": "100px" },
                            attrs: { src: _vm.characterData.image_url },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group w-100" }, [
                        _c("label", { attrs: { for: "image_url" } }, [
                          _vm._v("Portrait Url:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.getImageSearchUrl("concept"),
                              target: "_blank",
                            },
                          },
                          [_vm._v("[search]")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { id: "image_url", name: "image_url" },
                          domProps: {
                            value: _vm.exists(_vm.characterData, "image_url"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.updateCharacterPortrait(
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.isCustomizable
                          ? _c("div", [
                              _c("input", {
                                staticClass: "mr-1 input-sm",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: _vm.exists(
                                    _vm.characterData.template,
                                    "showPortrait"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateShowPortrait(
                                      $event.target.checked
                                    )
                                  },
                                },
                              }),
                              _c("span", [_vm._v("Show on sheet?")]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "image_url" } }, [
                        _vm._v("Description:"),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        staticClass: "form-control",
                        attrs: {
                          rows: "5",
                          id: "description",
                          name: "description",
                        },
                        domProps: {
                          value: _vm.exists(_vm.characterData, "description"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.characterData.description = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-md-flex" }, [
                      _c(
                        "div",
                        { staticClass: "col px-0" },
                        [
                          _c("label", { attrs: { for: "tags" } }, [
                            _vm._v("Tags:"),
                          ]),
                          _vm._v(" "),
                          _vm.characterData !== null
                            ? _c("vue-tags-input", {
                                attrs: {
                                  id: "tags",
                                  tags: _vm.characterData.tags,
                                },
                                on: {
                                  "tags-changed": function (newTags) {
                                    return _vm.updateTags(newTags)
                                  },
                                },
                                model: {
                                  value: _vm.tag,
                                  callback: function ($$v) {
                                    _vm.tag = $$v
                                  },
                                  expression: "tag",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.isCustomizable
                ? _c(
                    "div",
                    {
                      staticClass: "tab-pane fade",
                      attrs: {
                        id: "sheetProperties",
                        role: "tabpanel",
                        "aria-labelledby": "profile-tab",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column flex-md-row" },
                        [
                          _vm.characterData.template_id && _vm.appliedTemplate
                            ? _c(
                                "div",
                                { staticClass: "form-group col-12 col-md-6" },
                                [
                                  _c("label", [_vm._v("Applied Template:")]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.GetTemplateName) +
                                        "\n                  "
                                    ),
                                    _c("div", { staticClass: "small" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.GetTemplateDescription) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.SheetModifiedAfterTemplate
                                      ? _c(
                                          "div",
                                          { staticClass: "small text-muted" },
                                          [
                                            _vm._v(
                                              "\n                    Changes have been made to this sheet or the applied template has changed.\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-group col-12 col-md 6 d-flex flex-column",
                            },
                            [
                              _c("label", { attrs: { for: "template" } }, [
                                _vm._v("Find a Template:"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("autocomplete", {
                                    ref: "templateAutocomplete",
                                    staticClass: "mr-1 flex-fill",
                                    attrs: {
                                      search: _vm.searchTemplates,
                                      "debounce-time": 500,
                                      placeholder: "Find a template",
                                      "aria-label": "Find a template",
                                      "get-result-value":
                                        _vm.getTemplateResultValue,
                                    },
                                    on: { submit: _vm.selectTemplateResult },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "result",
                                          fn: function (ref) {
                                            var result = ref.result
                                            var props = ref.props
                                            return [
                                              _c(
                                                "li",
                                                _vm._b({}, "li", props, false),
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "p-0 m-0 h6",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(result.name) +
                                                          "\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "small" },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            result.description
                                                          ) +
                                                          "\n                          "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1174373782
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-link",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearTemplateSelection()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-times-circle",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _c("input", {
                                  ref: "templateSearchMine",
                                  staticClass: "mr-1 input-sm",
                                  attrs: { type: "checkbox" },
                                }),
                                _c("span", [
                                  _vm._v("Search only my templates?"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success btn-sm mr-1",
                                    attrs: {
                                      type: "button",
                                      "data-toggle": "modal",
                                      "data-target": "#modalSaveTemplate",
                                    },
                                  },
                                  [_vm._v("Save Template")]
                                ),
                                _vm._v(" "),
                                _vm.template.id
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary btn-sm mr-1",
                                        attrs: { type: "button" },
                                        on: { click: _vm.applyTemplate },
                                      },
                                      [_vm._v("Apply Template")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.IsTemplateOwner
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-danger btn-sm mr-1",
                                        attrs: { type: "button" },
                                        on: { click: _vm.deleteTemplate },
                                      },
                                      [_vm._v("Delete Template")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group flex-fill" },
                        [
                          _c("label", { attrs: { for: "sheet_logo" } }, [
                            _vm._v("Template Color:"),
                          ]),
                          _vm._v(" "),
                          _c("swatches-picker", {
                            attrs: { value: _vm.getTemplateColor },
                            on: { input: _vm.updateTemplateColor },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "sheet_logo" } }, [
                          _vm._v("Template Logo Url:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.getImageSearchUrl("tv show logos"),
                              target: "_blank",
                            },
                          },
                          [_vm._v("[search]")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          ref: "sheet_logo",
                          staticClass: "form-control",
                          attrs: { id: "sheet_logo", name: "sheet_logo" },
                          domProps: {
                            value: _vm.exists(
                              _vm.characterData.template,
                              "logo"
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.updateTemplateLogo($event.target.value)
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "sheet_logo" } }, [
                            _vm._v("Template Font:"),
                          ]),
                          _vm._v(" "),
                          _c("font-picker", {
                            attrs: {
                              "api-key":
                                "AIzaSyBPXSM04gZ5XajTtMnXr_z83loTBJ7qgDA",
                              options: _vm.fontPickerOptions,
                              "active-font": _vm.GetFont,
                            },
                            on: { change: _vm.applyFont },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "modal fade",
                          attrs: {
                            id: "modalSaveTemplate",
                            tabindex: "-1",
                            role: "dialog",
                            "aria-labelledby": "deleteLabel",
                            "aria-hidden": "true",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "modal-dialog",
                              attrs: { role: "document" },
                            },
                            [
                              _c(
                                "form",
                                {
                                  staticClass: "needs-validation",
                                  attrs: { novalidate: "", id: "formTemplate" },
                                },
                                [
                                  _c("div", { staticClass: "modal-content" }, [
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "modal-body" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("label", [
                                          _vm._v("Name (Max 50 characters)"),
                                        ]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.template.name,
                                              expression: "template.name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { required: "" },
                                          domProps: {
                                            value: _vm.template.name,
                                          },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.template,
                                                  "name",
                                                  $event.target.value
                                                )
                                              },
                                              function ($event) {
                                                return _vm.limit(50)
                                              },
                                            ],
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("label", [
                                          _vm._v(
                                            "Description (Max 100 characters)"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.template.description,
                                              expression:
                                                "template.description",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            maxlength: "250",
                                            required: "",
                                          },
                                          domProps: {
                                            value: _vm.template.description,
                                          },
                                          on: {
                                            input: [
                                              function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.template,
                                                  "description",
                                                  $event.target.value
                                                )
                                              },
                                              function ($event) {
                                                return _vm.limit(100)
                                              },
                                            ],
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                                Save this sheet layout as a template that can be applied to other Fate Anything sheets. To update\n                                one of your existing templates choose the same name. To create a new one, choose a new name.\n                                You must enter a short description of your template.\n                            "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "modal-footer" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-success",
                                          attrs: { type: "button" },
                                          on: { click: _vm.saveTemplate },
                                        },
                                        [_vm._v("Save")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-secondary",
                                          attrs: {
                                            type: "button",
                                            "data-dismiss": "modal",
                                          },
                                        },
                                        [_vm._v("Close")]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ])
      : _c(
          "div",
          { staticStyle: { visibility: "hidden" } },
          [
            _c("font-picker", {
              attrs: {
                "api-key": "AIzaSyBPXSM04gZ5XajTtMnXr_z83loTBJ7qgDA",
                options: _vm.fontPickerOptions,
                "active-font": _vm.GetFont,
              },
              on: { change: _vm.applyFont },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link active",
          attrs: {
            id: "character-tab",
            "data-toggle": "tab",
            href: "#characterProperties",
            role: "tab",
            "aria-controls": "character",
            "aria-selected": "true",
          },
        },
        [_vm._v("Character Properties")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "deleteLabel" } }, [
        _vm._v("Save Template"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }