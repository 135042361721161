var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sheet" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 col-md-4 order-md-2" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col text-center" }, [
            _c("img", {
              staticClass: "img-fluid fate-logo",
              attrs: { alt: "LOGO", src: _vm.$parent.GetSheetImage() },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6 text-center" }, [
            _c(
              "div",
              { staticClass: "fate-header", attrs: { for: "refresh" } },
              [_vm._v("Refresh")]
            ),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control text-center",
              attrs: {
                type: "number",
                id: "refresh",
                name: "refresh",
                placeholder: "Refresh",
              },
              domProps: { value: _vm.getVal("refresh") },
              on: {
                change: function ($event) {
                  return _vm.setVal("refresh", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6 text-center " },
            [
              _c(
                "div",
                { staticClass: "fate-header", attrs: { for: "fatepoints" } },
                [
                  _vm._v("\n\t\t\t\t\t\tFP "),
                  _vm.vttEnabled
                    ? _c(
                        "span",
                        { staticClass: "dice fo20 font-weight-normal small" },
                        [_vm._v("A")]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("inputfatepoints", _vm._g({}, _vm.$listeners)),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-8 order-md-1 " }, [
        _c("div", { staticClass: "d-flex" }, [
          _c("div", { staticClass: "form-group w-75 mr-1" }, [
            _c("div", { staticClass: "fate-header", attrs: { for: "name" } }, [
              _vm._v("ID"),
            ]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "name",
                name: "name",
                placeholder: "Name",
              },
              domProps: { value: _vm.getVal("name") },
              on: {
                change: function ($event) {
                  return _vm.setVal("name", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group w-25" }, [
            _c("div", { staticClass: "fate-header", attrs: { for: "name" } }, [
              _vm._v("Pronoun"),
            ]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "pronoun",
                name: "pronoun",
                placeholder: "Pronoun",
              },
              domProps: { value: _vm.getVal("pronoun") },
              on: {
                change: function ($event) {
                  return _vm.setVal("pronoun", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("textarea", {
            staticClass: "form-control",
            attrs: {
              id: "description",
              name: "description",
              rows: "3",
              placeholder: "Description",
            },
            domProps: { value: _vm.getVal("description") },
            on: {
              change: function ($event) {
                return _vm.setVal("description", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-6 fate-aspects" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.aspects, function (aspect) {
            return _c(
              "div",
              { key: aspect.obj, staticClass: "p-0 m-0" },
              [
                _c(
                  "inputaspect",
                  _vm._g({ attrs: { aspect: aspect } }, _vm.$listeners)
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-6 fate-skills" },
        [
          _c(
            "inputskillpyramid",
            _vm._g(
              {
                attrs: {
                  skills: _vm.skills,
                  header: "Skills",
                  skillList: _vm.skillList,
                },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-6" },
        [
          _c(
            "inputstuntextra",
            _vm._g(
              {
                attrs: {
                  item: "stunts",
                  rows: 25,
                  border: true,
                  header: "Stunts",
                },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-6" },
        [
          _c(
            "inputstuntextra",
            _vm._g(
              {
                attrs: {
                  item: "extras",
                  rows: 25,
                  border: true,
                  header: "Extras",
                },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 col-md-6 fate-stress" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
            _vm._v("Physical Stress "),
            _vm.vttEnabled
              ? _c("span", { staticClass: "dice fo20 font-weight-normal" }, [
                  _vm._v("D"),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex d-flex justify-content-between" },
          _vm._l(_vm.physicalstress, function (stress) {
            return _c(
              "div",
              { key: stress.obj },
              [
                _c(
                  "inputstress",
                  _vm._g(
                    { attrs: { stress: stress, stresstype: "Physical" } },
                    _vm.$listeners
                  )
                ),
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
            _vm._v("Mental Stress "),
            _vm.vttEnabled
              ? _c("span", { staticClass: "dice fo20 font-weight-normal" }, [
                  _vm._v("D"),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between" },
          _vm._l(_vm.mentalstress, function (stress) {
            return _c(
              "div",
              { key: stress.obj },
              [
                _c(
                  "inputstress",
                  _vm._g(
                    { attrs: { stress: stress, stresstype: "Mental" } },
                    _vm.$listeners
                  )
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6 col-sm-12 fate-consequences" },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("div", { staticClass: "fate-header col-12" }, [
              _vm._v("Consequences "),
              _vm.vttEnabled
                ? _c("span", { staticClass: "dice fo20 font-weight-normal" }, [
                    _vm._v("D"),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.consequences, function (consequence) {
            return _c(
              "div",
              { key: consequence.obj },
              [
                _c(
                  "inputconsequence",
                  _vm._g(
                    { attrs: { consequence: consequence } },
                    _vm.$listeners
                  )
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
        _vm._v("Aspects"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }