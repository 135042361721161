var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.anouncement
      ? _c("div", { staticClass: "d-print-none alert alert-danger m-0" }, [
          _c(
            "a",
            {
              staticClass: "close",
              staticStyle: { cursor: "pointer" },
              attrs: { "data-dismiss": "alert" },
            },
            [_vm._v("×")]
          ),
          _c("span", [
            _vm._v(_vm._s(_vm.anouncement.title) + " "),
            _c(
              "a",
              { attrs: { href: _vm.anouncement.html_url, target: "_blank" } },
              [_vm._v("[more]")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "container mt-2" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        !_vm.isSubscriber
          ? _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "card card m-2" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Become a Subscriber"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      "Register for free. Subscribe to get access to Scene builder, our Fate virtual table top (Players don't need a subscription to play.) and Roll20 integration."
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.isAuthenticated
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { href: "register" },
                        },
                        [
                          _c("span", { staticClass: "dice" }, [_vm._v("C")]),
                          _vm._v(" Create a Free Account"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAuthenticated
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { href: "account", type: "button" },
                        },
                        [
                          _c("span", { staticClass: "dice" }, [_vm._v("C")]),
                          _vm._v(" Become a Subscriber\n                "),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isSubscriber
          ? _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "card card m-2" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v("Build Your Scenes & Play Online"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(
                      "Build scenes for your fate sessions and import your existing characters and adversaries, then play online. Players don't need a subscription to play!"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isAuthenticated
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { href: "scene", type: "button" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-book-open" }),
                          _vm._v(" Build & Play Online\n                "),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _c("img", {
          staticClass: "img-fluid",
          attrs: { src: "/static/img/big-logo.png", alt: "Big Logo" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "card m-2" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v("View Your Fate Characters"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }, [
            _vm._v(
              "Create and manage all of your fate characters online. You can store and edit as many characters as you want using any of our Fate Character Sheets."
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary requires-auth hidden",
              attrs: { href: "character" },
            },
            [
              _c("i", { staticClass: "fas fa-user" }),
              _vm._v(" Play a Fate Character"),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary requires-noauth",
              attrs: { href: "login", type: "button" },
            },
            [
              _c("i", { staticClass: "fas fa-user" }),
              _vm._v(" Login to View Your Characters\n                "),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "card card m-2" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v("View Your Campaigns"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }, [
            _vm._v(
              "Create and manage multiple campaign settings. Record session notes and share them with your players. Build your world and start playing!"
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary requires-auth hidden",
              attrs: { href: "campaign" },
            },
            [
              _c("i", { staticClass: "fas fa-globe-americas" }),
              _vm._v(" Play a Campaign"),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary requires-noauth",
              attrs: { href: "login", type: "button" },
            },
            [
              _c("i", { staticClass: "fas fa-globe-americas" }),
              _vm._v(" Login to View Your Campaigns\n                "),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "card card m-2" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v("Find a Fate Character Sheet"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }, [
            _vm._v(
              "Find a character sheet for the setting you want. We have form-fillable Fate Core, Fate Accelerated and other setting character sheets."
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { href: "charactersheet" },
            },
            [
              _c("span", { staticClass: "dice" }, [_vm._v("D")]),
              _vm._v(" Find a Character Sheet"),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "card card m-2" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v("View the Adversary Codex"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }, [
            _vm._v(
              "View a codex of premade fate adversaries. If you can't find an adversary that you like, you can create your own and share it with the community."
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "btn btn-primary", attrs: { href: "adversary" } },
            [
              _c("span", { staticClass: "dice" }, [_vm._v("A")]),
              _vm._v(" Build & Play an Adversary"),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "card card m-2" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h5", { staticClass: "card-title" }, [
            _vm._v("Need Some Fate Dice?"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "card-text" }, [
            _vm._v(
              "Forgot your dice? Want to keep track of all your session rolls? We've got you covered! Use our fate dice roller to cover your game play needs."
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: {
                href: "#",
                "data-toggle": "modal",
                "data-target": "#modalDiceRoller",
              },
            },
            [
              _c("span", { staticClass: "dice" }, [_vm._v("+")]),
              _vm._v(" Roll Fate Dice"),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }