var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "fate-header d-flex" }, [
      _c("span", { staticClass: "mr-auto" }, [_vm._v(_vm._s(_vm.header))]),
      _vm._v(" "),
      !_vm.isNewCharacter
        ? _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.toggleEdit()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas d-print-none pr-2",
                class: {
                  "fa-check-circle": _vm.editing,
                  "fa-edit": !_vm.editing,
                },
              }),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.editing || _vm.isNewCharacter
      ? _c(
          "div",
          _vm._l(_vm.skills, function (skill) {
            return _c("div", { key: skill.obj }, [
              _c(
                "div",
                { staticClass: "d-md-flex" },
                [
                  _c("label", { staticClass: "pr-2 col-form-label mr-auto" }, [
                    _c("span", { staticClass: "d-md-none" }, [
                      _vm._v(_vm._s(skill.label) + " "),
                    ]),
                    _vm._v(_vm._s(skill.value)),
                  ]),
                  _vm._v(" "),
                  _vm._l(skill.items, function (item) {
                    return _c(
                      "div",
                      {
                        key: item,
                        staticClass: "d-flex justify-content-between py-1 px-1",
                      },
                      [
                        _vm.skillList
                          ? _c(
                              "select",
                              {
                                staticClass: "form-control",
                                attrs: {
                                  id: skill.obj + "." + item,
                                  name: "`${skill.obj}.${item}`",
                                  placeholder: "",
                                },
                                domProps: {
                                  value: _vm.$parent.getVal(
                                    skill.obj + "." + item
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$parent.setVal(
                                      skill.obj + "." + item,
                                      $event.target.value
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.skillList, function (thing) {
                                return _c(
                                  "option",
                                  { key: thing, domProps: { value: thing } },
                                  [_vm._v(_vm._s(thing))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.skillList
                          ? _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                id: skill.obj + "." + item,
                                name: "`${skill.obj}.${item}`",
                                placeholder: "",
                              },
                              domProps: {
                                value: _vm.$parent.getVal(
                                  skill.obj + "." + item
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$parent.setVal(
                                    skill.obj + "." + item,
                                    $event.target.value
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ])
          }),
          0
        )
      : _c(
          "div",
          _vm._l(_vm.skills, function (skill) {
            return _c("div", { key: skill.obj }, [
              _c(
                "div",
                { staticClass: "d-md-flex py-1" },
                [
                  _c("label", { staticClass: "pr-2 col-form-label" }, [
                    _vm._v(
                      _vm._s(skill.label) + " " + _vm._s(skill.value) + ":"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(skill.items, function (item, index) {
                    return _c(
                      "span",
                      {
                        key: item,
                        staticClass:
                          "d-md-flex justify-content-between py-1 my-1",
                      },
                      [
                        _vm.$parent.getVal(skill.obj + "." + item)
                          ? _c("span", [
                              index != 0 ? _c("span", [_vm._v(",")]) : _vm._e(),
                              _vm._v(" "),
                              _vm.vttEnabled
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "dice fo20",
                                      on: {
                                        click: function ($event) {
                                          _vm.sendToVTT(
                                            _vm.$parent.getVal(
                                              skill.obj + "." + item
                                            ),
                                            skill.value
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("+")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$parent.getVal(skill.obj + "." + item)
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ])
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }