var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("div", { staticClass: "d-flex mr-1" }, [
      _c("label", { staticClass: "mr-auto" }, [
        _vm._v(_vm._s(_vm.aspect.label)),
      ]),
      _vm._v(" "),
      _c("label", [_vm._v("Corrupted?")]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex mr-4" }, [
      _vm.vttEnabled
        ? _c(
            "span",
            {
              staticClass: "dice fo20 pt-2 pr-1",
              on: {
                click: function ($event) {
                  return _vm.sendToVTT(
                    "invoke",
                    "aspect",
                    "aspects",
                    _vm.aspect.obj
                  )
                },
              },
            },
            [_vm._v("C")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control mr-4",
        attrs: {
          type: "text",
          id: _vm.aspect.obj,
          name: _vm.aspect.obj,
          placeholder: _vm.aspect.label,
        },
        domProps: { value: _vm.$parent.getVal(_vm.aspect.obj) },
        on: {
          change: function ($event) {
            return _vm.$parent.setVal(_vm.aspect.obj, $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("div", [
        _c("input", {
          staticClass: "form-control corrupted-checkbox",
          attrs: {
            type: "checkbox",
            value: "1",
            id: _vm.aspect.corrupted_obj,
            name: _vm.aspect.corrupted_obj,
          },
          domProps: { checked: _vm.$parent.getVal(_vm.aspect.corrupted_obj) },
          on: {
            change: function ($event) {
              return _vm.$parent.setVal(
                _vm.aspect.corrupted_obj,
                $event.target.checked
              )
            },
          },
        }),
        _vm._v(" "),
        _c("div"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }