var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pl-1 ml-1 small" },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "span",
            {
              staticClass: "dice fo20",
              on: {
                click: function ($event) {
                  return _vm.sendToVTT()
                },
              },
            },
            [_vm._v("C")]
          ),
          _vm._v(" "),
          _c("editableinput", {
            staticClass: "font-weight-bold pr-1 mr-auto",
            attrs: { object: _vm.stuntextra, item: "name" },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0 m-0 small button-remove",
              attrs: { type: "button" },
            },
            [
              _c("i", {
                staticClass: "fas fa-trash-alt fa-xs",
                attrs: { title: "Remove stunt/extra" },
                on: {
                  click: function ($event) {
                    return _vm.removeStunt()
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("editableinput", {
        staticClass: "px-2",
        attrs: { object: _vm.stuntextra, type: "textarea", item: "value" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }