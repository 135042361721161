var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sheet" }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-8 order-md-1" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "fate-header", attrs: { for: "name" } }, [
            _vm._v("Character Name"),
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "name",
              name: "name",
              placeholder: "Name",
            },
            domProps: { value: _vm.getVal("name") },
            on: {
              change: function ($event) {
                return _vm.setVal("name", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-6 col-md-4 fate-approaches" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.approaches, function (approach) {
            return _c(
              "div",
              { key: approach.obj },
              [
                _c("inputapproach", {
                  attrs: {
                    item: approach,
                    inputclass: "round-input",
                    labelclass: "col-form-label mt-1",
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 col-md-8 fate-aspects" },
        [
          _vm._m(2),
          _vm._v(" "),
          _vm._l(_vm.aspects, function (aspect) {
            return _c(
              "div",
              { key: aspect.obj },
              [_c("inputaspect", { attrs: { aspect: aspect } })],
              1
            )
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 col-md-4" }, [
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "fate-scale" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "form-check" }, [
            _c("input", {
              staticClass: "form-check-input",
              attrs: {
                type: "radio",
                name: "scale",
                id: "scale[godlike]",
                value: "godlike",
              },
              domProps: { checked: _vm.getVal("scale.godlike") },
              on: {
                change: function ($event) {
                  return _vm.setVal("scale.godlike", $event.target.checked)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label col-form-label",
                attrs: { for: "scale[godlike]" },
              },
              [_vm._v("\n\t\t\t\t    Godlike\n\t\t\t\t  ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-check" }, [
            _c("input", {
              staticClass: "form-check-input",
              attrs: {
                type: "radio",
                name: "scale",
                id: "scale[legendary]",
                value: "legendary",
              },
              domProps: { checked: _vm.getVal("scale.legendary") },
              on: {
                change: function ($event) {
                  return _vm.setVal("scale.legendary", $event.target.checked)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label col-form-label",
                attrs: { for: "scale[legendary]" },
              },
              [_vm._v("\n\t\t\t\t\t\tLegendary\n\t\t\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-check" }, [
            _c("input", {
              staticClass: "form-check-input",
              attrs: {
                type: "radio",
                name: "scale",
                id: "scale[otherworldly]",
                value: "otherworldly",
              },
              domProps: { checked: _vm.getVal("scale.otherworldly") },
              on: {
                change: function ($event) {
                  return _vm.setVal("scale.otherworldly", $event.target.checked)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label col-form-label",
                attrs: { for: "scale[otherworldly]" },
              },
              [_vm._v("\n\t\t\t\t\t\tOtherworldly\n\t\t\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-check" }, [
            _c("input", {
              staticClass: "form-check-input",
              attrs: {
                type: "radio",
                name: "scale",
                id: "scale[supernatural]",
                value: "supernatural",
              },
              domProps: { checked: _vm.getVal("scale.supernatural") },
              on: {
                change: function ($event) {
                  return _vm.setVal("scale.supernatural", $event.target.checked)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label col-form-label",
                attrs: { for: "scale[supernatural]" },
              },
              [_vm._v("\n\t\t\t\t\t\tSupernatural\n\t\t\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-check" }, [
            _c("input", {
              staticClass: "form-check-input",
              attrs: {
                type: "radio",
                name: "scale",
                id: "scale[mundane]",
                value: "mundane",
              },
              domProps: { checked: _vm.getVal("scale.mundane") },
              on: {
                change: function ($event) {
                  return _vm.setVal("scale.mundane", $event.target.checked)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label col-form-label",
                attrs: { for: "scale[mundane]" },
              },
              [_vm._v("\n\t\t\t\t\t\tMundane\n\t\t\t\t\t")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-8 fate-mantles" }, [
        _vm._m(5),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("textarea", {
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "mantles",
              name: "mantles",
              rows: "2",
              placeholder: "Mantles",
            },
            domProps: { value: _vm.getVal("mantles") },
            on: {
              change: function ($event) {
                return _vm.setVal("mantles", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "inputstuntextra",
              _vm._g(
                {
                  attrs: {
                    item: _vm.stunts,
                    rows: 13,
                    border: true,
                    header: "Stunts",
                  },
                },
                _vm.$listeners
              )
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group d-md-flex justify-content-between" },
          [
            _vm.vttEnabled
              ? _c("span", { staticClass: "dice fo20 pt-2" }, [_vm._v("A")])
              : _vm._e(),
            _c("label", { staticClass: "col-form-label pr-1" }, [_vm._v("FP")]),
            _vm._v(" "),
            _c("inputfatepoints", { staticClass: "w-25" }),
            _vm._v(" "),
            _c("label", { staticClass: "col-form-label pr-1 w-25" }, [
              _vm._v("Refresh"),
            ]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control text-center w-25",
              attrs: {
                type: "number",
                id: "refresh",
                name: "refresh",
                placeholder: "Refresh",
              },
              domProps: { value: _vm.getVal("refresh") },
              on: {
                change: function ($event) {
                  return _vm.setVal("refresh", $event.target.value)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 col-md-4 fate-stress" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
            _vm._v("Stress "),
            _vm.vttEnabled
              ? _c("span", { staticClass: "dice fo20 font-weight-normal" }, [
                  _vm._v("D"),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.stresses, function (stress) {
            return _c(
              "div",
              { key: stress.obj, staticClass: "col mx-1" },
              [_c("inputstress", { attrs: { stress: stress } })],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row justify-content-between" },
          _vm._l(_vm.otherstresses, function (stress) {
            return _c(
              "div",
              { key: stress.obj, staticClass: "col" },
              [_c("inputstress", { attrs: { stress: stress } })],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-8 fate-conditions" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "fate-header col-12" }, [
            _vm._v("Conditions "),
            _vm.vttEnabled
              ? _c("span", { staticClass: "dice fo20 font-weight-normal" }, [
                  _vm._v("D"),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex d-flex justify-content-between" },
          [
            _c("inputcondition", {
              attrs: { condition: _vm.conditions.inperil },
            }),
            _vm._v(" "),
            _c("inputcondition", {
              attrs: { condition: _vm.conditions.doomed },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between" },
          [
            _c("inputcondition", {
              attrs: { condition: _vm.conditions.indebted },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("inputcondition", {
              attrs: { condition: _vm.conditions.condition1 },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("inputcondition", {
              attrs: { condition: _vm.conditions.condition2 },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {},
          [
            _c("inputcondition", {
              attrs: { condition: _vm.conditions.condition3 },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6 col-md-4 order-md-2" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col text-center fate-logo" }, [
          _c("label", [_vm._v("Dresden Files")]),
          _vm._v(" "),
          _c("label", [_vm._v("Accelerated")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "fate-header col-12" }, [_vm._v("Approaches")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
        _vm._v("Aspects"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fate-ladder d-none d-md-block" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "fate-header col-12" }, [_vm._v("Ladder")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("+8 Legendary"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("+7 Epic"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("+6 Fantastic"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("+5 Superb"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("+4 Great"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("+2 Fair"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("+1 Average"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("+0 Mediocre"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("–1 Poor"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c("label", { staticClass: "col-9 col-form-label" }, [
          _vm._v("-2 Terrible"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
        _vm._v("Scale (p.182)"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
        _vm._v("Mantles"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-12 fate-otherstress-label" }, [
        _vm._v("\n\t\t\t\t\tOther Types of Stress\n\t\t\t\t"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }