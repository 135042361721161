var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sheet" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 col-md-4 order-md-2" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col text-center" }, [
            _c("img", {
              staticClass: "img-fluid fate-logo",
              attrs: { alt: "Mouse Guard", src: _vm.$parent.GetSheetImage() },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6 text-center" }, [
            _c(
              "div",
              { staticClass: "fate-header", attrs: { for: "refresh" } },
              [_vm._v("Refresh")]
            ),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control text-center",
              attrs: {
                type: "number",
                id: "refresh",
                name: "refresh",
                placeholder: "Refresh",
              },
              domProps: { value: _vm.getVal("refresh") },
              on: {
                change: function ($event) {
                  return _vm.setVal("refresh", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6 text-center " },
            [
              _c(
                "div",
                { staticClass: "fate-header", attrs: { for: "fatepoints" } },
                [
                  _vm._v("\n\t\t\t\t\t\tFP "),
                  _vm.vttEnabled
                    ? _c(
                        "span",
                        { staticClass: "dice fo20 font-weight-normal small" },
                        [_vm._v("A")]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("inputfatepoints"),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-md-8 order-md-1" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "fate-header", attrs: { for: "name" } }, [
            _vm._v("Name"),
          ]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "name",
              name: "name",
              placeholder: "Name",
            },
            domProps: { value: _vm.getVal("name") },
            on: {
              change: function ($event) {
                return _vm.setVal("name", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("textarea", {
            staticClass: "form-control",
            attrs: {
              id: "description",
              name: "description",
              rows: "3",
              placeholder: "Description",
            },
            domProps: { value: _vm.getVal("description") },
            on: {
              change: function ($event) {
                return _vm.setVal("description", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-6 col-md-8 fate-aspects" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.aspects, function (aspect) {
            return _c(
              "div",
              { key: aspect.obj },
              [_c("inputaspect", { attrs: { aspect: aspect } })],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 col-md-4 fate-skills" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.approaches, function (approach) {
            return _c(
              "div",
              { key: approach.obj },
              [_c("inputapproach", { attrs: { item: approach } })],
              1
            )
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-6" },
        [
          _c(
            "inputstuntextra",
            _vm._g(
              {
                attrs: {
                  item: "stunts",
                  rows: 18,
                  border: true,
                  header: "Stunts, Wises & Gear",
                },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 col-md-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "fate-header" }, [_vm._v("Portrait")]),
          _vm._v(" "),
          _c("div", { staticClass: "col text-center" }, [
            _c("img", {
              staticClass: "img-fluid portrait",
              attrs: { alt: "Mouse Guard", src: _vm.getPortrait() },
            }),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row fate-conditions" }, [
      _c("div", { staticClass: "col-12 form-group" }, [
        _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
          _vm._v("Conditions "),
          _vm.vttEnabled
            ? _c("span", { staticClass: "dice fo20 font-weight-normal" }, [
                _vm._v("D"),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-6" },
        [
          _vm._m(2),
          _vm._v(" "),
          _c("inputcondition", {
            attrs: { condition: _vm.conditions.fleeting },
          }),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c("inputcondition", { attrs: { condition: _vm.conditions.sticky } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 col-md-6" }, [
        _vm._m(4),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-100" },
          [
            _c("inputcondition", {
              attrs: { condition: _vm.conditions.lasting, showvalue: "true" },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
        _vm._v("Aspects"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("div", { staticClass: "fate-header col-12" }, [_vm._v("Skills")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-10", attrs: { for: "stress" } }, [
        _c("u", { staticClass: "small" }, [_vm._v("Fleeting")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-10", attrs: { for: "stress" } }, [
        _c("u", { staticClass: "small" }, [_vm._v("Sticky")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "col-10", attrs: { for: "stress" } }, [
        _c("u", { staticClass: "small" }, [_vm._v("Lasting")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }