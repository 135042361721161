var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-2" },
    [
      _c("loading", { attrs: { loading: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", [
            _c("div", { staticClass: "d-flex flex-column flex-sm-row" }, [
              _c("h3", { staticClass: "mr-auto" }, [
                _vm._v(_vm._s(_vm.campaign.name) + " — Campaign"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex p-1" }, [
                _c("div", { staticClass: "mr-auto" }),
                _vm._v(" "),
                !_vm.showimportant
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-link d-sm-block d-md-none p-0 m-0",
                        attrs: {
                          title: "Show Important Things",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showimportant = true
                          },
                        },
                      },
                      [
                        _vm._v("\n          Show Important "),
                        _c("i", { staticClass: "fas fa-angle-double-left" }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showimportant
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-link d-sm-block d-md-none m-0 p-0 pl-1",
                        attrs: {
                          title: "Hide Important Things",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showimportant = false
                          },
                        },
                      },
                      [
                        _vm._v("\n          Hide Important "),
                        _c("i", { staticClass: "fas fa-angle-double-right" }),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            !_vm.isNewCampaign
              ? _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "order-2 order-md-1 px-1 col-12",
                      class: {
                        "col-md-7": _vm.showimportant,
                        "col-lg-8": _vm.showimportant,
                      },
                      attrs: { id: "logs" },
                    },
                    [
                      _c("div", { staticClass: "header d-flex" }, [
                        _c("span", { staticClass: "h4" }, [
                          _vm._v("Session Log"),
                        ]),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(" "),
                        _vm.sortDescending
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary btn-sm mr-1",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.sortDescending = false
                                  },
                                },
                              },
                              [_vm._m(1)]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.sortDescending
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary btn-sm mr-1",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.sortDescending = true
                                  },
                                },
                              },
                              [_vm._m(2)]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isFiltered,
                                expression: "isFiltered",
                              },
                            ],
                            staticClass: "btn btn-warning btn-sm mr-1",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.clearFilter()
                              },
                            },
                          },
                          [_vm._m(3)]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-md-none d-lg-none d-xl-none pt-1 ml-1",
                            on: {
                              click: function ($event) {
                                return _vm.jumpTo("#summary")
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-arrow-circle-up" })]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isLoading
                        ? _c("div", { staticClass: "p-5 h2" }, [
                            _vm._v("Loading sessions..."),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.getSortedSessions, function (session) {
                        return _c(
                          "div",
                          { key: session.id, staticClass: "mt-1" },
                          [
                            _c("div", { staticClass: "d-flex" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "badge badge-secondary mr-auto",
                                },
                                [_vm._v(_vm._s(_vm.getNiceDate(session.date)))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "cursor",
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpTo("#logs")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-arrow-circle-up mt-1 pt-2",
                                  }),
                                  _vm._v(" scroll up"),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card" },
                              [
                                _c("VueShowdown", {
                                  staticClass: "card-body",
                                  attrs: {
                                    extensions: ["fcsCampaignHidden"],
                                    options: {
                                      emoji: false,
                                      openLinksInNewWindow: true,
                                      parseImgDimensions: true,
                                    },
                                    markdown: session.description,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.showimportant
                    ? _c(
                        "div",
                        {
                          staticClass: "order-1 order-md-2 d-none d-md-block",
                          attrs: { id: "summary" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-link",
                              attrs: {
                                title: "Show Important Things",
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showimportant = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-angle-double-left",
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showimportant
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "order-1 order-md-2 px-1 col-12 col-md-5 col-lg-4",
                          attrs: { id: "summary" },
                        },
                        [
                          _c("div", { staticClass: "d-flex header" }, [
                            _c("h4", { staticClass: "mr-auto" }, [
                              _vm._v(
                                "\n            Important Things               \n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-md-none mt-1",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpTo("#logs")
                                  },
                                },
                              },
                              [
                                _vm._v("scroll down "),
                                _c("i", {
                                  staticClass: "fas fa-arrow-circle-down",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-link d-none d-md-block m-0 p-0 pl-1",
                                attrs: {
                                  title: "Hide Important Things",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.showimportant = false
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-angle-double-right",
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", {}, [
                            _vm._m(4),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(_vm.sortedAlphaSessions, function (thing) {
                                return _c(
                                  "li",
                                  { key: thing.id, staticClass: "d-flex" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-self-center pr-1",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-filter pr-1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.filterBy(thing.thing)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge badge-secondary mt-0",
                                          },
                                          [
                                            _vm._v(
                                              "x" +
                                                _vm._s(thing.sessionids.length)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-link p-0 text-danger text-left",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyThingToClipboard(
                                              thing.thing
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.niceThingDisplay(thing.thing)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm._m(5),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.sortedAlphaCharacters,
                                function (thing) {
                                  return _c(
                                    "li",
                                    { key: thing.id, staticClass: "d-flex" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-self-center pr-1",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-filter pr-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.filterBy(thing.thing)
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-secondary mt-0",
                                            },
                                            [
                                              _vm._v(
                                                "x" +
                                                  _vm._s(
                                                    thing.sessionids.length
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-link p-0 text-success text-left",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyThingToClipboard(
                                                thing.thing
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.niceThingDisplay(thing.thing)
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _vm._m(6),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.sortedAlphaFacePlaces,
                                function (thing) {
                                  return _c(
                                    "li",
                                    { key: thing.id, staticClass: "d-flex" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-self-center pr-1",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-filter pr-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.filterBy(thing.thing)
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-secondary mt-0",
                                            },
                                            [
                                              _vm._v(
                                                "x" +
                                                  _vm._s(
                                                    thing.sessionids.length
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-link p-0 text-info text-left",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyThingToClipboard(
                                                thing.thing
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.niceThingDisplay(thing.thing)
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _vm._m(7),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(_vm.sortedAlphaAspects, function (thing) {
                                return _c(
                                  "li",
                                  { key: thing.id, staticClass: "d-flex" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-self-center pr-1",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-filter pr-1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.filterBy(thing.thing)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge badge-secondary mt-0",
                                          },
                                          [
                                            _vm._v(
                                              "x" +
                                                _vm._s(thing.sessionids.length)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-link p-0 text-muted text-left",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyThingToClipboard(
                                              thing.thing
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.niceThingDisplay(thing.thing)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "pl-1 pt-1 mr-auto",
        attrs: {
          target: "_blank",
          href: "https://github.com/sheibeck/fcs/wiki/Campaigns",
        },
      },
      [_c("i", { staticClass: "fas fa-question-circle" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("i", { staticClass: "fas fa-sort-amount-down mt-1 mr-1" }),
      _c("span", { staticClass: "d-none d-md-block" }, [_vm._v("Sort")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("i", { staticClass: "fas fa-sort-amount-up mt-1 mr-1" }),
      _c("span", { staticClass: "d-none d-md-block" }, [_vm._v("Sort")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("i", { staticClass: "fas fa-times mt-1 mr-1" }),
      _vm._v(" "),
      _c("span", { staticClass: "d-none d-md-block" }, [
        _vm._v("Clear Filter"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "text-danger" }, [_vm._v("!")]),
      _vm._v("Issues"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "text-success" }, [_vm._v("#")]),
      _vm._v("Characters"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "text-info" }, [_vm._v("@")]),
      _vm._v("Faces & Places"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "text-muted" }, [_vm._v("~")]),
      _vm._v("Campaign Aspects"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }