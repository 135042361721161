var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mt-2 text-center" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row m-4 justify-content-md-center" }, [
      _vm.isAuthenticated
        ? _c("div", [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Email address: ")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.GetEmail()))]),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm.loaded && _vm.HasSubscription
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Subscription: ")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.SubscriptionStatus))]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.GotoManagePortal($event)
                        },
                      },
                    },
                    [_vm._v("[Manage]")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.loaded
              ? _c("div", { staticClass: "d-md-flex justify-content-center" }, [
                  _c("div", { staticClass: "card col col-md-6" }, [
                    _vm.HasSubscription
                      ? _c("div", { staticClass: "card-body" }, [
                          _c("p", [_vm._v("You are a subscriber, thank you!")]),
                          _vm._v(" "),
                          _vm._m(2),
                          _vm._v(" "),
                          _vm._m(3),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.HasSubscription
                      ? _c("div", { staticClass: "card-body" }, [
                          _vm._m(4),
                          _vm._v(" "),
                          _c("p", { staticClass: "card-text" }, [
                            _vm._v(
                              "\n              Pay a low monthly fee or a get a discounted rate by subscribing yearly \n              and you gain access to subscriber only benefits.\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._m(5),
                          _vm._v(" "),
                          _c("p", [
                            _c(
                              "button",
                              {
                                staticStyle: {
                                  "background-color": "#6772E5",
                                  color: "#FFF",
                                  padding: "8px 12px",
                                  border: "0",
                                  "border-radius": "4px",
                                  "font-size": "1em",
                                },
                                attrs: {
                                  id: "checkout-button-plan_HJ5iyg7r8S6mN8",
                                  role: "link",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Subscribe("monthly")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                $1.99 per Month\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(" — OR —")]),
                          _vm._v(" "),
                          _c("p", [
                            _c(
                              "button",
                              {
                                staticStyle: {
                                  "background-color": "#6772E5",
                                  color: "#FFF",
                                  padding: "8px 12px",
                                  border: "0",
                                  "border-radius": "4px",
                                  "font-size": "1em",
                                },
                                attrs: {
                                  id: "checkout-button-plan_HJ5iyg7r8S6mN8",
                                  role: "link",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Subscribe("yearly")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                $14.99 per Year\n              "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.loaded && _vm.HasSubscription
              ? _c("div", { staticClass: "pt-3" }, [
                  _vm.isAuthenticated
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          attrs: {
                            type: "button",
                            "data-toggle": "collapse",
                            "data-target": "#extension",
                            "aria-expanded": "true",
                            "aria-controls": "extension",
                          },
                        },
                        [
                          _vm._v(
                            "\n            Manual Extension Installation "
                          ),
                          _c("i", { staticClass: "fas fa-cog" }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "pt-2 collapse",
                      attrs: { id: "extension" },
                    },
                    [
                      _vm._m(6),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "text",
                          id: "devextid",
                          name: "devextid",
                        },
                        domProps: { value: _vm.GetExtensionId },
                        on: {
                          change: function ($event) {
                            return _vm.SetExtensionId($event.target.value)
                          },
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "ml-1 btn-primary btn-sm btn",
                          attrs: { type: "button" },
                        },
                        [_vm._v("save")]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 h1" }, [
        _vm._v("\n      Your Account\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 h4" }, [
        _vm._v("\n      View and update information about your account\n    "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Password: ")]),
      _vm._v(" "),
      _c("a", { attrs: { href: "/recover" } }, [
        _vm._v("\n          Change your password\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "For information about Roll20 integration please visit the\n              "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/sheibeck/fcs/wiki/Fate-of-20",
            target: "_blank",
          },
        },
        [_vm._v("Fate of 20 Page")]
      ),
      _vm._v(".\n            "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "For information about Scene Builder please visit the\n              "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/sheibeck/fcs/wiki/Scene-Builder",
            target: "_blank",
          },
        },
        [_vm._v("Scene Builder Page")]
      ),
      _vm._v(".\n            "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "card-title" }, [
      _vm._v("\n              Subscribe to Fate Character Sheet"),
      _c("br"),
      _vm._v(" "),
      _c("small", [_c("em", [_vm._v("Includes 30 day free trial.")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-weight-bold py-3" }, [
      _vm._v("\n              —Roll20 Integration"),
      _c("br"),
      _c("span", { staticClass: "small" }, [
        _vm._v(
          "requires a browser that can run Chrome extensions (Chrome, Opera, Edge)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "small" }, [
      _vm._v("Open browser extensions and copy the ID of "),
      _c("strong", [_vm._v("Fate of 20 extension")]),
      _vm._v(" here."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }