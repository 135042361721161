var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sheet apply-font-main" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col-sm-6 text-center order-md-2 text-md-right pb-2 pb-md-0 text-right d-md-flex",
        },
        [
          _c("div", { staticClass: "mr-auto d-none d-md-inline" }),
          _vm._v(" "),
          _c("img", {
            staticClass: "img-fluid fate-logo order-1 order-md-2",
            attrs: { alt: "Fate Anything", src: _vm.getTemplateLogo },
          }),
          _vm._v(" "),
          _vm.isOwner
            ? _c("div", { staticClass: "d-print-none order-2 order-md-1" }, [
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(
                    _vm._s(
                      "Customizations " +
                        (_vm.isEditLocked ? "locked" : "unlocked")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-link",
                    attrs: {
                      type: "button",
                      title:
                        "Click to " + (_vm.isEditLocked ? "unlock" : "lock"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateLockStatus()
                      },
                    },
                  },
                  [
                    _c("i", {
                      class: "fa fa-" + (_vm.isEditLocked ? "lock" : "unlock"),
                    }),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 d-flex flex-column flex-md-row order-md-1" },
        [
          _c("div", { staticClass: "form-group d-flex mr-md-3 col-md-8" }, [
            _c("label", { staticClass: "mt-2 mr-2" }, [_vm._v("Name")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "name",
                name: "name",
                placeholder: "Name",
              },
              domProps: { value: _vm.getVal("name") },
              on: {
                change: function ($event) {
                  return _vm.setVal("name", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group d-flex col-md-4" }, [
            _c("label", { staticClass: "mt-2 mr-2" }, [_vm._v("Pron.")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "pronoun",
                name: "pronoun",
                placeholder: "Pronoun",
              },
              domProps: { value: _vm.getVal("pronoun") },
              on: {
                change: function ($event) {
                  return _vm.setVal("pronoun", $event.target.value)
                },
              },
            }),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-sm-6 col-md-7 fate-aspects px-0",
          staticStyle: { "border-right": "2px solid #3A5224" },
        },
        [
          _c(
            "div",
            {
              staticClass: "fate-header d-flex",
              style: { backgroundColor: _vm.character.template.color },
            },
            [
              _c("div", { staticClass: "mr-auto" }, [_vm._v("Aspects")]),
              _vm._v(" "),
              !_vm.isEditLocked
                ? _c("div", [
                    _c("i", {
                      staticClass: "fas d-print-none fa-plus pr-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { title: "Add Aspect" },
                      on: {
                        click: function ($event) {
                          return _vm.addAspect()
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "px-1" },
            _vm._l(_vm.character.template.aspects, function (aspect) {
              return _c(
                "div",
                { key: aspect.id },
                [
                  _c("inputaspect", {
                    attrs: {
                      aspect: aspect,
                      customlabel: true,
                      showlabel: true,
                      removable: true,
                      editlock: _vm.isEditLocked,
                    },
                    on: { "remove-aspect": _vm.onRemoveAspect },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "inputstuntextra",
            _vm._g(
              {
                attrs: {
                  item: "stunts",
                  rows: 30,
                  border: false,
                  header: "Stunts",
                  headerColor: _vm.character.template.color,
                },
              },
              _vm.$listeners
            )
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fate-header mb-5 mb-sm-0",
              style: { backgroundColor: _vm.character.template.color },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("input", {
                  staticClass: "refresh pl-md-3",
                  attrs: {
                    type: "number",
                    id: "refresh",
                    name: "refresh",
                    placeholder: "3",
                  },
                  domProps: { value: _vm.getVal("refresh") },
                  on: {
                    change: function ($event) {
                      return _vm.setVal("refresh", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "pt-0" }, [_vm._v("Refresh")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "50px" } }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fate-header mb-5 mb-sm-0 d-flex",
              style: { backgroundColor: _vm.character.template.color },
            },
            [
              _c("div", { staticClass: "mr-auto" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  staticStyle: { "min-height": "50px" },
                },
                [
                  _vm.vttEnabled
                    ? _c(
                        "span",
                        { staticClass: "dice fo20 font-weight-normal" },
                        [_vm._v("A")]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "pt-0" }, [_vm._v("Fate Points")]),
                  _vm._v(" "),
                  _c("inputfatepoints", {
                    attrs: { inputclass: "fatepoints", placeholder: "-" },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 col-md-5 fate-skills px-0 mt-3 mt-sm-0" },
        [
          _vm.showPortrait
            ? _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: { alt: "Fate Anything", src: _vm.character.image_url },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fate-header d-flex",
              style: { backgroundColor: _vm.character.template.color },
            },
            [
              _c("div", { staticClass: "mr-auto" }, [
                _vm._v("Stress "),
                _vm.vttEnabled
                  ? _c(
                      "span",
                      { staticClass: "dice fo20 font-weight-normal" },
                      [_vm._v("D")]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              !_vm.isEditLocked
                ? _c("div", [
                    _c("i", {
                      staticClass: "fas d-print-none fa-plus pr-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { title: "Add Stress Track" },
                      on: {
                        click: function ($event) {
                          return _vm.addStressTrack()
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.character.template.stress, function (stress) {
            return _c(
              "div",
              {
                key: stress.id,
                staticClass: "d-md-flex flex-column pb-2 px-1",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group font-weight-bold pr-2 mt-0 border-bottom d-flex",
                  },
                  [
                    !_vm.isEditLocked
                      ? _c("input", {
                          staticClass:
                            "w-75 mr-auto inputlabel inputStressLabel",
                          attrs: {
                            type: "text",
                            id: "" + stress.label,
                            name: "" + stress.label,
                            placeholder: stress.placeholder,
                          },
                          domProps: { value: _vm.getVal("" + stress.label) },
                          on: {
                            change: function ($event) {
                              return _vm.setVal(
                                "" + stress.label,
                                $event.target.value
                              )
                            },
                          },
                        })
                      : _c("label", { staticClass: "inputStressLabel" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getStressTrackLabel(
                                stress.label,
                                stress.placeholder
                              )
                            )
                          ),
                        ]),
                    _vm._v(" "),
                    !_vm.isEditLocked
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-link text-secondary m-0 p-0",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.removeStressTrack(stress.id)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas d-print-none fa-minus-circle",
                              attrs: { title: "Delete Stress Track" },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isEditLocked
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-link text-secondary m-0 p-0",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addStressBox(stress.id)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas d-print-none fa-plus-circle",
                              attrs: { title: "Add Stress Box" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap justify-content-around" },
                  _vm._l(stress.boxes, function (box) {
                    return _c(
                      "div",
                      { key: box.id },
                      [
                        _c("inputstress", {
                          attrs: {
                            parentid: stress.id,
                            stress: box,
                            stresstype: _vm.getStressType(stress),
                            customlabel: true,
                            removable: true,
                            hidelabel: false,
                            editlock: _vm.isEditLocked,
                          },
                          on: { "remove-stress-box": _vm.onRemoveStressBox },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fate-header d-flex",
              style: { backgroundColor: _vm.character.template.color },
            },
            [
              _c("div", { staticClass: "mr-auto" }, [_vm._v("Consequences")]),
              _vm._v(" "),
              !_vm.isEditLocked
                ? _c("div", [
                    _c("i", {
                      staticClass: "fas d-print-none fa-plus pr-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { title: "Add Consequence" },
                      on: {
                        click: function ($event) {
                          return _vm.addConsequence()
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.character.template.consequences, function (consequence) {
            return _c(
              "div",
              { key: consequence.obj, staticClass: "px-1" },
              [
                _c("inputconsequence", {
                  attrs: {
                    consequence: consequence,
                    customlabel: true,
                    removable: true,
                    editlock: _vm.isEditLocked,
                  },
                  on: { "remove-consequence": _vm.onRemoveConsequence },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "fate-header d-flex mt-2",
              style: { backgroundColor: _vm.character.template.color },
            },
            [
              _c("div", { staticClass: "mr-auto" }, [_vm._v("Skills")]),
              _vm._v(" "),
              !_vm.isEditLocked
                ? _c("div", [
                    _c("i", {
                      staticClass: "fas d-print-none fa-plus pr-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { title: "Add Skill" },
                      on: {
                        click: function ($event) {
                          return _vm.addSkill()
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "px-1 skills" },
            _vm._l(_vm.character.template.skills, function (skill) {
              return _c(
                "div",
                { key: skill.obj },
                [
                  _c("inputskill", {
                    attrs: {
                      removable: true,
                      item: skill,
                      customlabel: true,
                      editlock: _vm.isEditLocked,
                    },
                    on: { "remove-skill": _vm.onRemoveSkill },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }