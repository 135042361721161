var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pl-1 ml-1 small d-flex" },
    [
      _c(
        "span",
        {
          staticClass: "dice fo20",
          on: {
            click: function ($event) {
              return _vm.sendToVTT()
            },
          },
        },
        [_vm._v("+")]
      ),
      _vm._v(" "),
      _c("editableinput", {
        staticClass: "font-weight-bold mr-1",
        attrs: { object: _vm.skill, item: "name" },
      }),
      _vm._v(" "),
      _c("editableinput", {
        staticClass: "mr-auto",
        attrs: { object: _vm.skill, item: "value" },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-link p-0 m-0 small button-remove",
          attrs: { type: "button" },
        },
        [
          _c("i", {
            staticClass: "fas fa-trash-alt fa-xs",
            attrs: { title: "Remove skill" },
            on: {
              click: function ($event) {
                return _vm.removeSkill()
              },
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }