var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-2" },
    [
      _c("loading", { attrs: { loading: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading && _vm.isNewScene
        ? _c("div", [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "name" } }, [_vm._v("Scene Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scene.name,
                    expression: "scene.name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "name", placeholder: "Scene Name" },
                domProps: { value: _vm.scene.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.scene, "name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "description" } }, [
                _vm._v("Description"),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.scene.description,
                    expression: "scene.description",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "description", rows: "3" },
                domProps: { value: _vm.scene.description },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.scene, "description", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.saveScene()
                  },
                },
              },
              [_vm._v("Create Scene")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading && !_vm.isNewScene,
              expression: "!isLoading && !isNewScene",
            },
          ],
          attrs: { id: "scene-detail" },
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              { staticClass: "mr-auto" },
              [
                _c("em", { staticStyle: { "vertical-align": "top" } }, [
                  _vm._v("Scene Aspects:"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.scene.aspects, function (aspect) {
                  return _c("sceneaspect", {
                    key: aspect.id,
                    attrs: { aspect: aspect, location: "scene" },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.isHost
              ? _c(
                  "div",
                  {
                    staticClass: "mr-2",
                    attrs: { title: "Storyteller Fate Points" },
                  },
                  [
                    _c("i", { staticClass: "fas fa-coins" }),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "text-center",
                      staticStyle: { width: "50px" },
                      attrs: { id: "fate-points", type: "number" },
                      domProps: { value: _vm.scene.fatepoints },
                      on: {
                        change: function ($event) {
                          return _vm.spendFate($event)
                        },
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isHost
              ? _c("span", [
                  _c(
                    "button",
                    {
                      staticClass: "btn-sm btn btn-success ml-1 d-none p-1",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.saveScene()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-save" }),
                      _vm._v(" Save Scene"),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isSceneRunning
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm btn btn-info",
                          attrs: {
                            type: "button",
                            disabled: _vm.waitingForGameStart,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.startGame()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-play" }),
                          _vm._v(" Start Game"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSceneRunning
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm btn btn-danger",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.stopGame()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-stop-circle" }),
                          _vm._v(" Stop Game"),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            (_vm.isSceneRunning && _vm.isHost) || !_vm.isHost
              ? _c("span", [
                  !_vm.isConnected
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm btn btn-secondary ml-1",
                          attrs: {
                            type: "button",
                            disabled: _vm.waitingToJoin,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.joinGame()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-sign-language" }),
                          _vm._v(" Join Game"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isConnected
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm btn btn-secondary ml-1",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.exitGame()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fas fa-sign-language" }),
                          _vm._v(" Exit Game"),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            1 == 2
              ? _c("span", [
                  !_vm.fullScreen
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm btn btn-secondary ml-1",
                          attrs: { title: "Fullscreen", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleFullScreen(true)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-expand-alt" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fullScreen
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-sm btn btn-secondary ml-1",
                          attrs: { title: "Exit fullscreen", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleFullScreen(false)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-compress-alt" })]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex", attrs: { id: "game-table" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "dragscroll",
                    rawName: "v-dragscroll.noleft",
                    value: true,
                    expression: "true",
                    modifiers: { noleft: true },
                  },
                ],
                ref: "dragscroll",
                staticClass: "mr-auto",
                attrs: { id: "canvas-wrapper" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "bg-light mt-2",
                    attrs: { id: "scene-canvas", "data-dragscroll": "" },
                  },
                  _vm._l(_vm.scene.zones, function (zone) {
                    return _c("scenezone", {
                      key: zone.id,
                      staticClass: "panzoom-exclude",
                      attrs: { zone: zone },
                    })
                  }),
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex flex-column align-items-end",
                attrs: { id: "scene-toolbar" },
              },
              [
                _vm.isHost
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            attrs: { type: "button", title: "New Round" },
                            on: {
                              click: function ($event) {
                                return _vm.startNewRound()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-undo" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            attrs: {
                              type: "button",
                              title: "Add Scene Aspect",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addAspect()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-sticky-note" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            attrs: { type: "button", title: "Add Zone" },
                            on: {
                              click: function ($event) {
                                return _vm.addZone()
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-shapes" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-link",
                            attrs: {
                              id: "save-chatlog",
                              type: "button",
                              variant: "link",
                              title: "Save chat to campaign",
                            },
                          },
                          [_c("i", { staticClass: "fas fa-feather" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-popover",
                          {
                            ref: "popoverCampaign",
                            attrs: {
                              triggers: "click blur",
                              target: "save-chatlog",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [_vm._v("Save chat log to campaign")]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1823080195
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("autocomplete", {
                              attrs: {
                                title: "Search Campaign",
                                search: _vm.searchCampaigns,
                                "debounce-time": 500,
                                placeholder: "Select Campaign",
                                "aria-label": "Select Campaign",
                                "get-result-value": _vm.getCampaignResultValue,
                              },
                              on: { submit: _vm.selectCampaignResult },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "result",
                                    fn: function (ref) {
                                      var result = ref.result
                                      var props = ref.props
                                      return [
                                        _c(
                                          "li",
                                          _vm._b({}, "li", props, false),
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "p-0 m-0 h6" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(result.name) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1640207887
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-column mx-auto" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link",
                      attrs: { type: "button", title: "Fate SRD" },
                      on: {
                        click: function ($event) {
                          return _vm.openFateSRD()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-book" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link",
                      attrs: { type: "button", title: "Reset Zoom" },
                      on: {
                        click: function ($event) {
                          return _vm.resetZoom()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-search-plus" })]
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-link",
                      attrs: {
                        href: "/scene/" + _vm.id,
                        title: "Copy scene link",
                      },
                      on: { click: _vm.shareUrl },
                    },
                    [_c("i", { staticClass: "fas fa-link" })]
                  ),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _vm.showchat
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          attrs: { title: "Hide chat", type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.showchat = false
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-angle-double-right" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.showchat
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          attrs: { title: "Show chat", type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.showchat = true
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-angle-double-left" })]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.isHost
                  ? _c("div", { staticClass: "d-flex flex-column mt-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          attrs: { type: "button", title: "Clear chat log" },
                          on: {
                            click: function ($event) {
                              return _vm.clearChatLog()
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-comment-slash" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          attrs: { type: "button", title: "Clear scene" },
                          on: {
                            click: function ($event) {
                              return _vm.clearScene()
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-broom" })]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.showchat
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column h-100",
                    attrs: { id: "chat" },
                  },
                  [
                    _c("VueShowdown", {
                      ref: "chatlog",
                      staticClass: "border mb-1 px-1",
                      staticStyle: {
                        width: "300px !important",
                        "overflow-wrap": "break-word",
                        "word-wrap": "break-word",
                      },
                      attrs: {
                        id: "chat-log",
                        options: _vm.vueShowdownOpts,
                        markdown: _vm.chatLog,
                      },
                    }),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.chatMessage,
                          expression: "chatMessage",
                        },
                      ],
                      staticClass: "w-100 mr-1",
                      attrs: { rows: "3", id: "chat-input" },
                      domProps: { value: _vm.chatMessage },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.sendChatMessage()
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.chatMessage = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex mt-1" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedPlayer,
                              expression: "selectedPlayer",
                            },
                          ],
                          staticClass: "form-control mr-1",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedPlayer = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "everyone" } }, [
                            _vm._v("Everyone"),
                          ]),
                          _vm._v(" "),
                          _vm._l(this.scene.players, function (player) {
                            return _c(
                              "option",
                              {
                                key: player.playerId,
                                domProps: { value: player.playerId },
                              },
                              [_vm._v(_vm._s(player.userName))]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-sm mr-1",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.sendChatMessage()
                            },
                          },
                        },
                        [_vm._v("Send")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info btn-sm",
                          attrs: { title: "Roll 4df", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.rollFateDice()
                            },
                          },
                        },
                        [_c("span", { staticClass: "dice" }, [_vm._v("+")])]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        class: { "d-none": !_vm.isConnected, "d-flex": _vm.isConnected },
        attrs: { id: "player-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal",
          attrs: {
            id: "modalSettings",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "modalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", attrs: { role: "document" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _vm.isHost
                      ? _c(
                          "div",
                          { attrs: { id: "Host-Settings" } },
                          [
                            _vm.isHost
                              ? _c("editableinput", {
                                  attrs: {
                                    object: _vm.scene,
                                    item: "name",
                                    canedit: _vm.isHost,
                                    label: "Scene Name:",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("label", [_vm._v("Scene Description:")]),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.scene.description,
                                  expression: "scene.description",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { rows: "3" },
                              domProps: { value: _vm.scene.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.scene,
                                    "description",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("hr"),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getPlayer(_vm.userId)
                      ? _c("editableinput", {
                          attrs: {
                            object: _vm.getPlayer(_vm.userId),
                            item: "userName",
                            label: "Username:",
                            showEditIcon: "true",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.mediaSettings.useVideo,
                            expression: "mediaSettings.useVideo",
                          },
                        ],
                        attrs: { type: "checkbox", id: "usevideo" },
                        domProps: {
                          checked: Array.isArray(_vm.mediaSettings.useVideo)
                            ? _vm._i(_vm.mediaSettings.useVideo, null) > -1
                            : _vm.mediaSettings.useVideo,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.mediaSettings.useVideo,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.mediaSettings,
                                      "useVideo",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.mediaSettings,
                                      "useVideo",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.mediaSettings, "useVideo", $$c)
                              }
                            },
                            function ($event) {
                              return _vm.updateOnlineSettings($event)
                            },
                          ],
                        },
                      }),
                      _vm._v(" Use video when playing online\n          "),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.mediaSettings.useAudio,
                            expression: "mediaSettings.useAudio",
                          },
                        ],
                        attrs: { type: "checkbox", id: "useaudio" },
                        domProps: {
                          checked: Array.isArray(_vm.mediaSettings.useAudio)
                            ? _vm._i(_vm.mediaSettings.useAudio, null) > -1
                            : _vm.mediaSettings.useAudio,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.mediaSettings.useAudio,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.mediaSettings,
                                      "useAudio",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.mediaSettings,
                                      "useAudio",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.mediaSettings, "useAudio", $$c)
                              }
                            },
                            function ($event) {
                              return _vm.updateOnlineSettings($event)
                            },
                          ],
                        },
                      }),
                      _vm._v(" Use audio when playing online\n          "),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [_vm._v("Video Device")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          staticClass: "form-control",
                          domProps: { value: _vm.getSelectedVideoDevice },
                          on: {
                            change: function ($event) {
                              return _vm.selectMediaDevice($event, "video")
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "default" } }, [
                            _vm._v("System Default"),
                          ]),
                          _vm._v(" "),
                          _vm._l(this.videoDevices, function (device) {
                            return _c(
                              "option",
                              {
                                key: device.id,
                                domProps: { value: device.deviceId },
                              },
                              [_vm._v(_vm._s(device.label))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [_vm._v("Microphone")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          staticClass: "form-control",
                          domProps: { value: _vm.getSelectedMicrophoneDevice },
                          on: {
                            change: function ($event) {
                              return _vm.selectMediaDevice($event, "microphone")
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "default" } }, [
                            _vm._v("System Default"),
                          ]),
                          _vm._v(" "),
                          _vm._l(this.microphoneDevices, function (device) {
                            return _c(
                              "option",
                              {
                                key: device.id,
                                domProps: { value: device.deviceId },
                              },
                              [_vm._v(_vm._s(device.label))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(3),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-link",
        attrs: {
          type: "button",
          title: "Settings",
          "data-toggle": "modal",
          "data-target": "#modalSettings",
        },
      },
      [_c("i", { staticClass: "fas fa-cog" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-link",
        attrs: {
          href: "https://github.com/sheibeck/fcs/wiki/Scene-Builder",
          target: "_blank",
          title: "Help",
        },
      },
      [_c("i", { staticClass: "fas fa-question" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "modalLabel" } }, [
        _vm._v("Scene Settings"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [_vm._v("Close")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }