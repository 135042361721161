var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pl-1 ml-1 small" },
    [
      _c(
        "span",
        {
          staticClass: "dice fo20",
          on: {
            click: function ($event) {
              return _vm.sendToVTT()
            },
          },
        },
        [_vm._v("C")]
      ),
      _vm._v(" "),
      !_vm.editing
        ? _c(
            "span",
            {
              staticClass: "font-weight-bold",
              attrs: { title: "Click to edit" },
              on: {
                click: function ($event) {
                  _vm.editing = true
                },
              },
            },
            [_vm._v(_vm._s(_vm.consequence.name.toTitleCase()))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("invoke", {
        staticClass: "pt-0",
        attrs: { invokes: _vm.consequence.invokes },
      }),
      _vm._v(" "),
      _vm.editing
        ? _c("div", { staticClass: "input-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.consequence.name,
                  expression: "consequence.name",
                },
              ],
              staticClass: "form-control-sm",
              domProps: { value: _vm.consequence.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.consequence, "name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "button",
                {
                  staticClass: "input-group-text",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.editing = false
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-check-circle text-success" })]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex mr-auto" },
        [
          _vm.consequence.label != true && _vm.consequence.label != false
            ? _c("editableinput", {
                staticClass: "mt-1",
                attrs: { object: _vm.consequence, item: "label" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.consequence.value,
                expression: "consequence.value",
              },
            ],
            staticClass: "ml-1 form-control-sm",
            attrs: { type: "text" },
            domProps: { value: _vm.consequence.value },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.consequence, "value", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          !_vm.isCharacter
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link p-0 m-0 small",
                  attrs: { type: "button" },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-trash-alt fa-xs",
                    attrs: { title: "Remove consequence" },
                    on: {
                      click: function ($event) {
                        return _vm.removeConsequence()
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }