var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-2" },
    [
      _c("div", { staticClass: "js-adversary-list" }, [
        !_vm.id
          ? _c(
              "div",
              { staticClass: "d-print-none mb-2 d-md-flex" },
              [
                !_vm.isAuthenticated
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary mb-1 mb-md-0 mr-auto",
                        attrs: { href: "/login", type: "button" },
                      },
                      [
                        _vm._v("\n          Login to Create an Adversary "),
                        _c("span", { staticClass: "dice" }, [_vm._v("A")]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-success js-create-adversary mb-1 mb-md-0",
                        attrs: {
                          role: "button",
                          href: "/adversary/create/new-adversary/edit",
                        },
                      },
                      [
                        _vm._v("\n        Create Adversary "),
                        _c("i", { staticClass: "fa fa-plus" }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "pt-0 pt-md-3 pl-2 mr-auto d-flex justify-content-between",
                      },
                      [
                        _c("div", [
                          _c("input", {
                            ref: "myAdversaries",
                            attrs: { type: "checkbox", id: "my_adversaries" },
                            domProps: { checked: _vm.adversaryListDefault },
                            on: {
                              change: function ($event) {
                                return _vm.toggleAdversaryListDefault()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "my_adversaries" },
                            },
                            [_vm._v("Show only my adversaries?")]
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.moreAdversaries
                          ? _c(
                              "div",
                              { staticClass: "small d-none d-md-block ml-5" },
                              [
                                _vm._v(
                                  "\n          Viewing " +
                                    _vm._s(_vm.totalAdversaries) +
                                    " adversaries\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.moreAdversaries
                          ? _c(
                              "div",
                              { staticClass: "small d-none d-md-block ml-5" },
                              [
                                _vm._v(
                                  "\n          Found " +
                                    _vm._s(_vm.totalAdversaries) +
                                    " adversaries on " +
                                    _vm._s(_vm.currentPage) +
                                    " of " +
                                    _vm._s(_vm.pagesScanned) +
                                    " pages\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("search", {}),
              ],
              1
            )
          : _c("div", { staticClass: "mb-2" }, [_vm._m(0)]),
      ]),
      _vm._v(" "),
      _c("pager", {
        attrs: {
          show: _vm.moreAdversaries,
          more: _vm.moreDataAvailable,
          firstpage: _vm.currentPage == 1,
          lastpage: _vm.isLastPage,
        },
        on: { "get-page": _vm.handlePage },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-columns", attrs: { id: "adversaryDetail" } },
        _vm._l(_vm.adversaries, function (item) {
          return _c("div", { key: item.id, staticClass: "card" }, [
            item.image_url
              ? _c("img", {
                  staticClass: "card-img-top list-image",
                  attrs: { src: item.image_url, alt: item.name + "Image" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "h4",
              { staticClass: "card-header adversary-name bg-light d-flex" },
              [
                _c(
                  "a",
                  {
                    staticClass: "mr-auto",
                    staticStyle: { "text-decoration": "none" },
                    attrs: {
                      href:
                        "/adversary/" +
                        _vm.commonSvc.GetId(item.id) +
                        "/" +
                        item.slug,
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _vm._v(" "),
                _vm.isAuthenticated
                  ? _c(
                      "a",
                      {
                        staticClass: "d-print-none",
                        staticStyle: { color: "#888 !important" },
                        attrs: {
                          href:
                            "/adversary/" +
                            _vm.commonSvc.GetId(item.id) +
                            "/" +
                            item.slug +
                            "/copy",
                          title: "Copy Adversary",
                        },
                      },
                      [_c("i", { staticClass: "fas fa-copy fa-xs" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isOwner(item.owner_id)
                  ? _c(
                      "a",
                      {
                        staticClass: "d-print-none pl-1",
                        staticStyle: { color: "#888 !important" },
                        attrs: {
                          href:
                            "/adversary/" +
                            _vm.commonSvc.GetId(item.id) +
                            "/" +
                            item.slug +
                            "/edit",
                          title: "Edit Adversary",
                        },
                      },
                      [_c("i", { staticClass: "fa fa-edit fa-xs" })]
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            !_vm.isEmpty(item.aspects)
              ? _c("div", [
                  _c("h5", { staticClass: "card-header py-0" }, [
                    _vm._v("Aspects"),
                  ]),
                  _vm._v(" "),
                  Array.isArray(item.aspects)
                    ? _c(
                        "div",
                        _vm._l(item.aspects, function (aspect, index) {
                          return _c(
                            "p",
                            {
                              key: index,
                              staticClass: "card-text px-4 my-0",
                              attrs: { id: "aspect-" + index },
                            },
                            [
                              index == 0
                                ? _c("strong", [_vm._v("High Concept")])
                                : _vm._e(),
                              _vm._v(" "),
                              index == 1
                                ? _c("strong", [_vm._v("Trouble")])
                                : _vm._e(),
                              _vm._v(" "),
                              index > 1
                                ? _c("strong", [_vm._v("Aspect")])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.fixLabel(
                                      item.name,
                                      aspect.name,
                                      "aspect"
                                    )
                                  ),
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", [
                        item.aspects.high_concept
                          ? _c(
                              "p",
                              {
                                staticClass: "card-text px-4 my-0",
                                attrs: { id: "highconcept" },
                              },
                              [
                                _c("strong", [_vm._v("High Concept")]),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.fixLabel(
                                        item.name,
                                        item.aspects.high_concept,
                                        "aspect"
                                      )
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.aspects.trouble
                          ? _c(
                              "p",
                              {
                                staticClass: "card-text px-4 my-0",
                                attrs: { id: "trouble" },
                              },
                              [
                                _c("strong", [_vm._v("Trouble")]),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.fixLabel(
                                        item.name,
                                        item.aspects.trouble,
                                        "aspect"
                                      )
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.aspects.other_aspects
                          ? _c(
                              "p",
                              {
                                staticClass: "card-text px-4 my-0",
                                attrs: { id: "otherapsects" },
                              },
                              [
                                _c("strong", [_vm._v("Aspects")]),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.fixLabel(
                                        item.name,
                                        item.aspects.other_aspects,
                                        "aspect"
                                      )
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isEmpty(item.skills)
              ? _c("div", [
                  _c("h5", { staticClass: "card-header py-0" }, [
                    _vm._v("Skills"),
                  ]),
                  _vm._v(" "),
                  Array.isArray(item.skills)
                    ? _c(
                        "div",
                        _vm._l(item.skills, function (skill, index) {
                          return _c(
                            "p",
                            {
                              key: index,
                              staticClass: "card-text px-4 my-0",
                              attrs: { id: "skill-" + index },
                            },
                            [
                              _c("strong", [_vm._v(_vm._s(skill.name))]),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.fixLabel(
                                      item.name,
                                      skill.value,
                                      "skill",
                                      skill.name
                                    )
                                  ),
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        _vm._l(item.skills, function (skill, index) {
                          return _c(
                            "p",
                            {
                              key: index,
                              staticClass: "card-text px-4 my-0",
                              attrs: { id: "skill-" + index },
                            },
                            [
                              _c("strong", [_vm._v(_vm._s(index))]),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.fixLabel(
                                      item.name,
                                      skill,
                                      "skill",
                                      index
                                    )
                                  ),
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isEmpty(item.stunts)
              ? _c("div", [
                  _c("h5", { staticClass: "card-header py-0" }, [
                    _vm._v("Stunts & Extras"),
                  ]),
                  _vm._v(" "),
                  Array.isArray(item.stunts)
                    ? _c(
                        "div",
                        _vm._l(item.stunts, function (stunt, stuntIndex) {
                          return _c(
                            "p",
                            {
                              key: stuntIndex,
                              staticClass: "card-text px-4 my-0",
                              attrs: { id: "stunt-" + stuntIndex },
                            },
                            [
                              _vm.vttEnabled
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "dice fo20",
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendToVTT(
                                            item.name,
                                            "stuntextra",
                                            stunt.name,
                                            stunt.value
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("A")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("strong", [_vm._v(_vm._s(stunt.name))]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.fixLabel(item.name, stunt.value)) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        _vm._l(item.stunts, function (stunt, stuntIndex) {
                          return _c(
                            "p",
                            {
                              key: stuntIndex,
                              staticClass: "card-text px-4 my-0",
                              attrs: { id: "stunt-" + stuntIndex },
                            },
                            [
                              _vm.vttEnabled
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "dice fo20",
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendToVTT(
                                            item.name,
                                            "stuntextra",
                                            stuntIndex,
                                            stunt
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("A")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("strong", [_vm._v(_vm._s(stuntIndex))]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.fixLabel(item.name, stunt)) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isEmpty(item.stress)
              ? _c("div", [
                  _c("h5", { staticClass: "card-header py-0" }, [
                    _vm._v("Stress "),
                    _vm.vttEnabled
                      ? _c(
                          "span",
                          { staticClass: "dice fo20 font-weight-normal" },
                          [_vm._v("D")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  Array.isArray(item.stunts)
                    ? _c(
                        "div",
                        _vm._l(item.stress, function (stressTrack, index) {
                          return _c(
                            "p",
                            {
                              key: index,
                              staticClass: "card-text px-4 my-0",
                              attrs: { id: "stresstrack-" + index },
                            },
                            [
                              _c("strong", [_vm._v(_vm._s(stressTrack.name))]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.getStressBoxes(stressTrack.value),
                                function (stressBox, boxIndex) {
                                  return _c(
                                    "span",
                                    {
                                      key: boxIndex,
                                      attrs: {
                                        id:
                                          "stresstrack-" +
                                          index +
                                          "-stressbox-" +
                                          boxIndex,
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "checkbox" },
                                        domProps: { value: stressBox.value },
                                        on: {
                                          change: function ($event) {
                                            return _vm.sendToVTT(
                                              item.name,
                                              "stress",
                                              "" +
                                                stressBox +
                                                (stressTrack.name !== "Stress"
                                                  ? " " + stressTrack.name
                                                  : ""),
                                              $event.target.checked
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(stressBox) + "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        _vm._l(
                          item.stress,
                          function (stressMain, stressMainIndex) {
                            return _c(
                              "p",
                              {
                                key: stressMainIndex,
                                staticClass: "card-text px-4 my-0",
                                attrs: { id: "stresstrack-" + stressMainIndex },
                              },
                              [
                                _c("strong", [_vm._v(_vm._s(stressMainIndex))]),
                                _vm._v(" "),
                                _vm._l(
                                  stressMain,
                                  function (stressValue, stressIndex) {
                                    return _c("span", { key: stressIndex }, [
                                      _c("input", {
                                        attrs: { type: "checkbox" },
                                        domProps: { value: stressValue },
                                        on: {
                                          change: function ($event) {
                                            return _vm.sendToVTT(
                                              item.name,
                                              "stress",
                                              "" +
                                                stressValue +
                                                (stressMainIndex !== "Stress"
                                                  ? " " + stressMainIndex
                                                  : ""),
                                              $event.target.checked
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(stressValue) + "\n              "
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isEmpty(item.consequences)
              ? _c("div", [
                  _c("h5", { staticClass: "card-header py-0" }, [
                    _vm._v("Consequences "),
                    _vm.vttEnabled
                      ? _c(
                          "span",
                          { staticClass: "dice fo20 font-weight-normal" },
                          [_vm._v("D")]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  Array.isArray(item.stunts)
                    ? _c(
                        "div",
                        _vm._l(item.consequences, function (con, conIndex) {
                          return _c(
                            "p",
                            {
                              key: conIndex,
                              staticClass:
                                "form-inline card-text px-4 my-0 d-flex",
                              attrs: { id: "consequence-" + conIndex },
                            },
                            [
                              _vm.vttEnabled
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "dice fo20",
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendToVTT(
                                            item.name,
                                            "invoke",
                                            "invoke",
                                            _vm.consequences[conIndex]
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("A")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.fixLabel(item.name, con)
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("strong", [_vm._v(_vm._s(con.value))]),
                              _vm._v(" "),
                              _vm.vttEnabled
                                ? _c("input", {
                                    staticClass: "ml-2 form-control input-sm",
                                    on: {
                                      change: function ($event) {
                                        return _vm.sendToVTT(
                                          item.name,
                                          "consequence",
                                          con.name + " " + con.value,
                                          $event.target.value,
                                          conIndex
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        _vm._l(item.consequences, function (con, conIndex) {
                          return _c(
                            "p",
                            {
                              key: conIndex,
                              staticClass:
                                "form-inline card-text px-4 my-0 d-flex",
                              attrs: { id: "consequence-" + conIndex },
                            },
                            [
                              _vm.vttEnabled
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "dice fo20",
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendToVTT(
                                            item.name,
                                            "invoke",
                                            "invoke",
                                            _vm.consequences[conIndex]
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("A")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("strong", [_vm._v(_vm._s(conIndex))]),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.fixLabel(item.name, con)
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _vm.vttEnabled
                                ? _c("input", {
                                    staticClass: "ml-2 form-control input-sm",
                                    on: {
                                      change: function ($event) {
                                        return _vm.sendToVTT(
                                          item.name,
                                          "consequence",
                                          con + " " + conIndex,
                                          $event.target.value,
                                          conIndex
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-footer" },
              [
                _c(
                  "span",
                  {
                    staticClass: "badge badge-dark js-adversary-tag",
                    attrs: { "data-search-text": item.system },
                    on: { click: _vm.searchByTag },
                  },
                  [_vm._v(_vm._s(item.system))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "badge badge-dark js-adversary-tag",
                    attrs: { "data-search-text": item.genre },
                    on: { click: _vm.searchByTag },
                  },
                  [_vm._v(_vm._s(item.genre))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class:
                      _vm.badgeColor(item.type) + " badge js-adversary-tag",
                    attrs: { "data-search-text": item.type },
                    on: { click: _vm.searchByTag },
                  },
                  [_vm._v(_vm._s(item.type))]
                ),
                _vm._v(" "),
                _vm._l(item.tags, function (tag, index) {
                  return _c(
                    "span",
                    {
                      key: tag.text,
                      staticClass: "badge badge-secondary mr-1",
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        id: "tag-" + index,
                        "data-search-text": tag.text,
                      },
                      on: { click: _vm.searchByTag },
                    },
                    [
                      _vm._v(
                        "                \n            " +
                          _vm._s(tag.text) +
                          "\n          "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("pager", {
        attrs: {
          show: _vm.moreAdversaries,
          more: _vm.moreDataAvailable,
          firstpage: _vm.currentPage == 1,
          lastpage: _vm.isLastPage,
        },
        on: { "get-page": _vm.handlePage },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "btn btn-success js-create-adversary mb-1 mb-md-0 d-print-none",
        attrs: { role: "button", href: "/adversary" },
      },
      [
        _c("i", { staticClass: "fas fa-chevron-circle-left" }),
        _vm._v(" Adversary List\n      "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }