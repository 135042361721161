var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-2" },
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", [
            _c(
              "nav",
              {
                staticClass:
                  "nav nav-pills flex-column flex-sm-row mb-3 border-bottom pb-2",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "text-sm-center nav-link",
                    class: { active: _vm.isActiveTab("yours") },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.activateTab("yours")
                      },
                    },
                  },
                  [_vm._v("Your scenes")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "text-sm-center nav-link",
                    class: { active: _vm.isActiveTab("others") },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.activateTab("others")
                      },
                    },
                  },
                  [_vm._v("Scenes you've played")]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isActiveTab("yours")
              ? _c("div", [
                  _vm.isSubscriber
                    ? _c("div", [
                        _vm.isAuthenticated
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-print-none mb-2 hide-on-detail d-md-flex",
                              },
                              [_vm._m(0), _vm._v(" "), _c("search", {})],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.hasScenes
                          ? _c("div", [
                              _c("h2", [
                                _vm._v("You have not created any scenes."),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasScenes
                          ? _c(
                              "div",
                              { staticClass: "card-columns" },
                              _vm._l(_vm.scenes, function (item) {
                                return _c(
                                  "div",
                                  { key: item.id, staticClass: "card" },
                                  [
                                    _c("div", { staticClass: "card-body" }, [
                                      _c("h5", { staticClass: "card-title" }, [
                                        _vm._v(_vm._s(item.name)),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        item.image_url
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "col-12 col-md-5 text-center",
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "img-fluid",
                                                  attrs: {
                                                    src: item.image_url,
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          { staticClass: "card-text col" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.getShortText(
                                                    item.description
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("hr"),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: {
                                              href:
                                                "/scene/" +
                                                _vm.commonSvc.GetId(item.id),
                                              "data-id": item.id,
                                            },
                                          },
                                          [
                                            _vm._v("Play "),
                                            _c("i", {
                                              staticClass: "fa fa-play-circle",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn-secondary text-white ml-1 mr-auto",
                                            attrs: {
                                              href:
                                                "/scene/" +
                                                _vm.commonSvc.GetId(item.id),
                                            },
                                            on: { click: _vm.shareUrl },
                                          },
                                          [
                                            _vm._v("Share "),
                                            _c("i", {
                                              staticClass: "fa fa-share-square",
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "btn",
                                            staticStyle: { color: "red" },
                                            attrs: {
                                              href: "#",
                                              "data-id": item.id,
                                              "data-toggle": "modal",
                                              "data-target":
                                                "#modalDeleteConfirm",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash",
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "card-footer text-muted" },
                                      [
                                        item.players
                                          ? _c(
                                              "div",
                                              { staticClass: "small" },
                                              [
                                                _vm._v(
                                                  "\n                Players: " +
                                                    _vm._s(
                                                      item.players.length
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-secondary",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                "data-search-text":
                                                  _vm.commonSvc.GetId(
                                                    item.related_id
                                                  ),
                                              },
                                              on: { click: _vm.searchByTag },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.commonSvc.GetId(
                                                    item.related_id
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "hidden",
                          attrs: { id: "copyUrl" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal fade",
                            attrs: {
                              id: "modalDeleteConfirm",
                              tabindex: "-1",
                              role: "dialog",
                              "aria-labelledby": "deleteLabel",
                              "aria-hidden": "true",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "modal-dialog",
                                attrs: { role: "document" },
                              },
                              [
                                _c("div", { staticClass: "modal-content" }, [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "modal-footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger js-delete",
                                        attrs: { type: "button" },
                                        on: { click: _vm.deleteScene },
                                      },
                                      [_vm._v("Delete")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary",
                                        attrs: {
                                          type: "button",
                                          "data-dismiss": "modal",
                                        },
                                      },
                                      [_vm._v("Close")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSubscriber
                    ? _c("div", [_c("ctasubscribe")], 1)
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isActiveTab("others")
              ? _c("div", [
                  !_vm.hasParticipated
                    ? _c("div", [
                        _c("h2", [
                          _vm._v(
                            "You have not participated in others' scenes."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasParticipated
                    ? _c(
                        "div",
                        { staticClass: "card-columns" },
                        _vm._l(_vm.othersScenes, function (item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "card" },
                            [
                              _c("div", { staticClass: "card-body" }, [
                                _c("h5", { staticClass: "card-title" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  item.image_url
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "col-12 col-md-5 text-center",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "img-fluid",
                                            attrs: { src: item.image_url },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "card-text col" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.getShortText(item.description)
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: {
                                        href:
                                          "/scene/" +
                                          _vm.commonSvc.GetId(item.id),
                                        "data-id": item.id,
                                      },
                                    },
                                    [
                                      _vm._v("Play "),
                                      _c("i", {
                                        staticClass: "fa fa-play-circle",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-secondary text-white ml-1 mr-auto",
                                      attrs: {
                                        href:
                                          "/scene/" +
                                          _vm.commonSvc.GetId(item.id),
                                      },
                                      on: { click: _vm.shareUrl },
                                    },
                                    [
                                      _vm._v("Share "),
                                      _c("i", {
                                        staticClass: "fa fa-share-square",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn",
                                      staticStyle: { color: "red" },
                                      attrs: {
                                        href: "#",
                                        "data-id": item.id,
                                        "data-toggle": "modal",
                                        "data-target": "#modalDeleteConfirm",
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-trash" })]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "card-footer text-muted" },
                                [
                                  item.players
                                    ? _c("div", { staticClass: "small" }, [
                                        _vm._v(
                                          "\n              Players: " +
                                            _vm._s(item.players.length) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "badge badge-secondary",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          "data-search-text":
                                            _vm.commonSvc.GetId(
                                              item.related_id
                                            ),
                                        },
                                        on: { click: _vm.searchByTag },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.commonSvc.GetId(item.related_id)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "hidden",
                    attrs: { id: "copyUrl" },
                  }),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-success mr-auto mb-1 mb-md-0",
        attrs: { href: "/scene/create" },
      },
      [_vm._v("Create a Scene "), _c("i", { staticClass: "fas fa-book-open" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "deleteLabel" } }, [
        _vm._v("Confirm Scene Delete"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", [_vm._v("Are you sure you want to delete this scene?")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }