var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-1 p-1 bg-light border d-flex scene-object",
      style: {
        position: "absolute",
        top: _vm.objectdata.y + "px",
        left: _vm.objectdata.x + "px",
      },
      attrs: { id: "scene-object-" + _vm.commonSvc.GetId(_vm.objectdata.id) },
      on: {
        click: function ($event) {
          _vm.bringToFront(
            "scene-object-" + _vm.commonSvc.GetId(_vm.objectdata.id)
          )
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "bg-secondary text-white mr-1 p-1 drag-handle d-flex flex-column justify-content-between",
        },
        [
          _c("i", {
            staticClass: "fas fa-expand-arrows-alt",
            attrs: { title: "Move" },
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "fas fa-eye-slash fa-xs pt-2",
            attrs: { title: "Hide All" },
            on: {
              click: function ($event) {
                return _vm.showAllSections(false)
              },
            },
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "fas fa-eye fa-xs pt-2 mb-auto",
            attrs: { title: "Show All" },
            on: {
              click: function ($event) {
                return _vm.showAllSections(true)
              },
            },
          }),
          _vm._v(" "),
          _vm.isFCSObject
            ? _c(
                "button",
                {
                  staticClass: "p-0 m-0 text-white btn btn-link",
                  attrs: {
                    title: "Play " + _vm.FCSObjectType,
                    target: "blank",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openLink()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-external-link-alt fa-xs" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isCharacter
            ? _c(
                "button",
                {
                  staticClass: "p-0 m-0 text-white btn btn-link",
                  attrs: { title: "Refresh Data", target: "blank" },
                  on: {
                    click: function ($event) {
                      return _vm.syncCharacter()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-sync-alt fa-xs" })]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mr-auto w-100" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "d-flex w-100 mr-auto", style: _vm.getBgColor },
            [
              _c("editableinput", {
                staticClass: "font-weight-bold pl-1",
                attrs: { object: _vm.objectdata, item: "name" },
              }),
              _vm._v(" "),
              _vm.objectdata.image_url && !_vm.imageEdit
                ? _c("img", {
                    staticClass: "rounded-circle portrait",
                    attrs: { src: _vm.objectdata.image_url, alt: "portrait" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.imageEdit
          ? _c("div", [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("Portrait Url"),
              ]),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.objectdata.image_url,
                      expression: "objectdata.image_url",
                    },
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.objectdata.image_url },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.objectdata, "image_url", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "input-group-append",
                    staticStyle: { height: "38px" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "input-group-text",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.imageEdit = false
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-check-circle text-success",
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href:
                        "https://www.google.com/search?safe=strict&tbm=isch&q=" +
                        this.objectdata.name,
                      title: "Search for image",
                    },
                  },
                  [_vm._v("Search for image")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "header d-flex" }, [
            _c("span", { staticClass: "mr-auto" }, [_vm._v("Aspects")]),
            _vm._v(" "),
            !_vm.isCharacter
              ? _c("i", {
                  staticClass: "fas fa-plus-circle fa-sm pt-1",
                  attrs: { title: "Add aspect" },
                  on: {
                    click: function ($event) {
                      return _vm.addThingToObject("aspect")
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.objectdata.show.aspects
              ? _c("i", {
                  staticClass: "fas fa-chevron-circle-up fa-sm pt-1",
                  attrs: { title: "Hide" },
                  on: {
                    click: function ($event) {
                      _vm.objectdata.show.aspects = false
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.objectdata.show.aspects
              ? _c("i", {
                  staticClass: "fas fa-chevron-circle-down fa-sm pt-1",
                  attrs: { title: "Show" },
                  on: {
                    click: function ($event) {
                      _vm.objectdata.show.aspects = true
                    },
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.objectdata.show.aspects
            ? _c(
                "div",
                _vm._l(_vm.sortedAspects, function (aspect) {
                  return _c(
                    "div",
                    { key: aspect.id },
                    [
                      _c("aspect", {
                        attrs: {
                          aspect: aspect,
                          location: "thing",
                          type: _vm.objectdata.object_type,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        !_vm.isCharacter
          ? _c("div", [
              _c("div", { staticClass: "header d-flex" }, [
                _c("span", { staticClass: "mr-auto" }, [_vm._v("Skills")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fas fa-plus-circle fa-sm pt-1",
                  attrs: { title: "Add skill" },
                  on: {
                    click: function ($event) {
                      return _vm.addThingToObject("skill")
                    },
                  },
                }),
                _vm._v(" "),
                _vm.objectdata.show.skills
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-up fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.skills = false
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.objectdata.show.skills
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-down fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.skills = true
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.objectdata.show.skills
                ? _c(
                    "div",
                    _vm._l(_vm.objectdata.skills, function (skill) {
                      return _c(
                        "div",
                        { key: skill.id },
                        [
                          _c("skill", {
                            attrs: { skill: skill, location: "thing" },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isCharacter
          ? _c("div", [
              _c("div", { staticClass: "header d-flex" }, [
                _c("span", { staticClass: "mr-auto" }, [
                  _vm._v("Stunts/Extras"),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fas fa-plus-circle fa-sm pt-1",
                  attrs: { title: "Add stunt/extra" },
                  on: {
                    click: function ($event) {
                      return _vm.addThingToObject("stuntextra")
                    },
                  },
                }),
                _vm._v(" "),
                _vm.objectdata.show.stuntextras
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-up fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.stuntextras = false
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.objectdata.show.stuntextras
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-down fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.stuntextras = true
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.objectdata.show.stuntextras
                ? _c(
                    "div",
                    _vm._l(_vm.objectdata.stuntextras, function (stuntextra) {
                      return _c(
                        "div",
                        { key: stuntextra.id },
                        [
                          _c("stuntextra", {
                            attrs: {
                              stuntextra: stuntextra,
                              location: "thing",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isCharacter
          ? _c("div", [
              _c("div", { staticClass: "header d-flex" }, [
                _c("span", { staticClass: "mr-auto" }, [_vm._v("Stress")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "fas fa-plus-circle fa-sm pt-1",
                  attrs: { title: "Add stress track" },
                  on: {
                    click: function ($event) {
                      return _vm.addThingToObject("stress")
                    },
                  },
                }),
                _vm._v(" "),
                _vm.objectdata.show.stress
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-up fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.stress = false
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.objectdata.show.stress
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-down fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.stress = true
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.objectdata.show.stress
                ? _c(
                    "div",
                    _vm._l(this.objectdata.stress, function (stress) {
                      return _c(
                        "div",
                        { key: stress.id },
                        [
                          _c("stress", {
                            attrs: { stress: stress, location: "thing" },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.objectdata.conditions
          ? _c("div", [
              _c("div", { staticClass: "header d-flex" }, [
                _c("span", { staticClass: "mr-auto" }, [_vm._v("Conditions")]),
                _vm._v(" "),
                !_vm.isCharacter
                  ? _c("i", {
                      staticClass: "fas fa-plus-circle fa-sm pt-1",
                      attrs: { title: "Add condition" },
                      on: {
                        click: function ($event) {
                          return _vm.addThingToObject("condition")
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.objectdata.show.conditions
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-up fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.conditions = false
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.objectdata.show.conditions
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-down fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.conditions = true
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.objectdata.show.conditions
                ? _c(
                    "div",
                    _vm._l(this.objectdata.conditions, function (condition) {
                      return _c(
                        "div",
                        { key: condition.id },
                        [
                          _c("stress", {
                            attrs: {
                              stress: condition,
                              location: "thing",
                              type: "CONDITION",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        (_vm.isCharacter &&
          _vm.objectdata.consequences &&
          _vm.objectdata.consequences.length > 0) ||
        !_vm.isCharacter
          ? _c("div", [
              _c("div", { staticClass: "header d-flex" }, [
                _c("span", { staticClass: "mr-auto" }, [
                  _vm._v("Consequences"),
                ]),
                _vm._v(" "),
                !_vm.isCharacter
                  ? _c("i", {
                      staticClass: "fas fa-plus-circle fa-sm pt-1",
                      attrs: { title: "Add consequence" },
                      on: {
                        click: function ($event) {
                          return _vm.addThingToObject("consequence")
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.objectdata.show.consequences
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-up fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.consequences = false
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.objectdata.show.consequences
                  ? _c("i", {
                      staticClass: "fas fa-chevron-circle-down fa-sm pt-1",
                      on: {
                        click: function ($event) {
                          _vm.objectdata.show.consequences = true
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.objectdata.show.consequences
                ? _c(
                    "div",
                    _vm._l(_vm.objectdata.consequences, function (consequence) {
                      return _c(
                        "div",
                        { key: consequence.id },
                        [
                          _c("consequence", {
                            attrs: {
                              consequence: consequence,
                              location: "thing",
                              type: _vm.objectdata.object_type,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("div", { staticClass: "mt-1 mb-1 separator" }),
            _vm._v(" "),
            _vm._l(_vm.objectdata.caAndBoost, function (aspect) {
              return _c("aspect", { key: aspect.id, attrs: { aspect: aspect } })
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column bg-light ml-1 border toolbar" },
        [
          !_vm.objectdata.acted
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link p-0",
                  attrs: { type: "button", title: "Has not acted yet" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleTurn()
                    },
                  },
                },
                [_c("i", { staticClass: "far fa-hourglass text-warning" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.objectdata.acted
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link p-0",
                  attrs: { type: "button", title: "Has taken an action" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleTurn()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-hourglass text-danger" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0",
              attrs: { type: "button", title: "Create advantage/boost" },
              on: {
                click: function ($event) {
                  return _vm.addThingToObject("caAndBoost")
                },
              },
            },
            [_c("i", { staticClass: "fas fa-sticky-note" })]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "btn btn-link p-0",
              attrs: {
                id: "move-object-" + this.objectdata.id,
                type: "button",
                variant: "link",
                title: "Move to Zone",
              },
            },
            [_c("i", { staticClass: "fas fa-running" })]
          ),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              ref: "popoverZonePicker",
              attrs: {
                target: "move-object-" + this.objectdata.id,
                triggers: "click blur",
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Move to Zone")]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedZone,
                      expression: "selectedZone",
                    },
                  ],
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedZone = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.moveObjectToZone,
                    ],
                  },
                },
                _vm._l(_vm.zoneList, function (zone) {
                  return _c(
                    "option",
                    { key: zone.id, domProps: { value: zone.id } },
                    [_vm._v(_vm._s(zone.name))]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "btn btn-link p-0",
              attrs: {
                id: "color-object-" + this.objectdata.id,
                type: "button",
                variant: "link",
                title: "Change Color",
              },
            },
            [_c("i", { staticClass: "fas fa-palette" })]
          ),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              ref: "popoverColorPicker",
              attrs: {
                target: "color-object-" + this.objectdata.id,
                triggers: "click blur",
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Change Color")]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("label", [_vm._v("Background")]),
              _vm._v(" "),
              _c("swatches-picker", {
                attrs: { value: _vm.getBackgroundColor },
                on: { input: _vm.updateObjectBackgroundColor },
              }),
              _vm._v(" "),
              _c("label", [_vm._v("Text")]),
              _vm._v(" "),
              _c("swatches-picker", {
                attrs: { value: _vm.getTextColor },
                on: { input: _vm.updateObjectTextColor },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0",
              attrs: { type: "button", title: "Edit portrait" },
              on: {
                click: function ($event) {
                  _vm.imageEdit = true
                },
              },
            },
            [_c("i", { staticClass: "fas fa-image" })]
          ),
          _vm._v(" "),
          !_vm.isCharacter
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link p-0 mt-auto",
                  attrs: { type: "button", title: "Make a copy" },
                  on: {
                    click: function ($event) {
                      return _vm.copyObject()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-copy" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0",
              class: { "mt-auto": _vm.isCharacter },
              attrs: { type: "button", title: "Remove" },
              on: {
                click: function ($event) {
                  return _vm.removeObject(_vm.objectdata.id)
                },
              },
            },
            [_c("i", { staticClass: "fas fa-trash-alt" })]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }