var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", { staticClass: "d-flex justify-content-center" }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-5 h2" }, [
      _vm._v("Loading ... "),
      _c("i", { staticClass: "fas fa-cog fa-spin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }