var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex" }, [
    !_vm.editing
      ? _c(
          "div",
          {
            staticClass: "editable",
            attrs: { title: "Click to edit" },
            on: {
              click: function ($event) {
                return _vm.toggleEdit()
              },
            },
          },
          [
            _vm.label ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
            _vm._v("\n    " + _vm._s(_vm.getThing) + "\n  "),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.editing,
            expression: "editing",
          },
        ],
      },
      [
        !_vm.type
          ? _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.object[_vm.item],
                    expression: "object[item]",
                  },
                ],
                ref: "editor",
                staticClass: "form-control-sm",
                domProps: { value: _vm.object[_vm.item] },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.toggleEdit()
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.object, _vm.item, $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    staticClass: "input-group-text",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.editing = false
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-check-circle text-success" })]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "textarea"
          ? _c("div", { staticClass: "input-group d-flex flex-column" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.object[_vm.item],
                    expression: "object[item]",
                  },
                ],
                ref: "editor",
                staticClass: "w-100",
                attrs: { rows: "3" },
                domProps: { value: _vm.object[_vm.item] },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.object, _vm.item, $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.editing = false
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-check-circle text-success" }),
                  _vm._v(" Save"),
                ]
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }