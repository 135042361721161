var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mt-2 text-center" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row m-4 justify-content-md-center" }, [
      _c("div", { staticClass: "col-sm-12 col-md-4" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "email" } }, [_vm._v("Email address")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "email",
              id: "email",
              "aria-describedby": "emailHelp",
              placeholder: "Enter email",
            },
            domProps: { value: _vm.email },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.login.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "password" } }, [_vm._v("Password")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "password",
              id: "password",
              placeholder: "Password",
            },
            domProps: { value: _vm.password },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.login.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary col-sm-12 col-md-5 mt-1 mb-1",
            attrs: { type: "button" },
            on: { click: _vm.login },
          },
          [
            _vm._v("\n          Login "),
            _c("i", { staticClass: "fas fa-sign-in-alt" }),
          ]
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "col-sm-12 col-md-5 mt-1 mb-1",
            attrs: { href: "/recover" },
          },
          [_vm._v("\n          Forgot your password?\n      ")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "col-sm-12 col-md-5 mt-1 mb-1",
            attrs: { href: "#" },
            on: { click: _vm.resendConfirmationCode },
          },
          [
            _vm._v(
              "\n          Resend your confirmation email?            \n      "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 h1" }, [
        _vm._v("\n      Login\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 h4" }, [
        _vm._v(
          "\n      Login to save and manage your characters and adversaries.\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-secondary col-sm-12 col-md-5 mt-1 mb-1 rounded-2",
        attrs: { href: "/register", type: "button", role: "button" },
      },
      [
        _vm._v("\n          Register "),
        _c("i", { staticClass: "fas fa-user-plus" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }