import { render, staticRenderFns } from "./CharacterList.vue?vue&type=template&id=53becbf3&"
import script from "./CharacterList.vue?vue&type=script&lang=js&"
export * from "./CharacterList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fcs/fcs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53becbf3')) {
      api.createRecord('53becbf3', component.options)
    } else {
      api.reload('53becbf3', component.options)
    }
    module.hot.accept("./CharacterList.vue?vue&type=template&id=53becbf3&", function () {
      api.rerender('53becbf3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/CharacterList.vue"
export default component.exports