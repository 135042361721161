var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card m-2" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("h5", { staticClass: "card-title" }, [_vm._v("Become a Subscriber")]),
      _vm._v(" "),
      _c("p", { staticClass: "card-text" }, [
        _vm._v(
          "Subscribe to get access to enhanced features like Scene builder, our Fate virtual table top, and Roll20 integration."
        ),
      ]),
      _vm._v(" "),
      !_vm.isAuthenticated
        ? _c(
            "a",
            { staticClass: "btn btn-primary", attrs: { href: "register" } },
            [
              _c("span", { staticClass: "dice" }, [_vm._v("C")]),
              _vm._v(" Create a Free Account"),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuthenticated
        ? _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { href: "account", type: "button" },
            },
            [
              _c("span", { staticClass: "dice" }, [_vm._v("C")]),
              _vm._v(" Become a Subscriber\n    "),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }