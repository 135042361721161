var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "p-0 m-0" },
    [
      _vm._l(_vm.invokes, function (invoke) {
        return _c("input", {
          key: invoke.id,
          attrs: { type: "checkbox", title: "Use invoke" },
          domProps: { checked: invoke.used },
          on: {
            change: function ($event) {
              return _vm.toggleInvoke($event, invoke.id)
            },
          },
        })
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-link p-0 m-0",
          attrs: { type: "button", title: "Add invoke" },
          on: {
            click: function ($event) {
              return _vm.addInvoke()
            },
          },
        },
        [_c("i", { staticClass: "fas fa-plus-circle fa-xs" })]
      ),
      _vm._v(" "),
      _vm.invokes.length > 0
        ? _c(
            "button",
            {
              staticClass: "btn btn-link p-0 m-0",
              attrs: { type: "button", title: "Remove invoke" },
              on: {
                click: function ($event) {
                  return _vm.removeInvoke()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-minus-circle fa-xs" })]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }