var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group d-flex align-items-center" }, [
    _vm.vttEnabled
      ? _c(
          "span",
          {
            staticClass: "dice fo20 pt-2 order-1",
            on: {
              click: function ($event) {
                return _vm.sendToVTT()
              },
            },
          },
          [_vm._v("+")]
        )
      : _vm._e(),
    _vm._v(" "),
    (!_vm.item.label && !_vm.customlabel) || (_vm.customlabel && _vm.editlock)
      ? _c(
          "div",
          {
            staticClass: "w-100 mr-auto d-flex flex-column order-3 ml-2",
            class: { "mt-1": _vm.item.description },
          },
          [
            _c(
              "label",
              {
                staticClass: "col-form-label inputlabel p-0",
                class: _vm.labelclass,
              },
              [_vm._v(_vm._s(_vm.getLabel))]
            ),
            _vm._v(" "),
            _vm.item.description
              ? _c("small", { staticClass: "skill-desc" }, [
                  _vm._v(_vm._s(_vm.item.description)),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    (_vm.item.label || _vm.customlabel) && !_vm.editlock
      ? _c("input", {
          staticClass: "w-100 mr-auto inputlabel order-3 ml-2",
          class: _vm.labelclass,
          attrs: {
            type: "text",
            id: "" + _vm.item.label,
            name: "" + _vm.item.label,
            placeholder: _vm.item.placeholder,
          },
          domProps: { value: _vm.$parent.getVal("" + _vm.item.label) },
          on: {
            change: function ($event) {
              return _vm.$parent.setVal(
                "" + _vm.item.label,
                $event.target.value
              )
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control text-center col-3 pr-3 pr-md-0 order-2",
      class: _vm.inputclass,
      attrs: {
        type: "number",
        id: _vm.item.obj,
        name: _vm.item.obj,
        placeholder: "+",
      },
      domProps: { value: _vm.$parent.getVal("" + _vm.item.obj) },
      on: {
        change: function ($event) {
          return _vm.$parent.setVal("" + _vm.item.obj, $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm.removable && !_vm.editlock
      ? _c(
          "button",
          {
            staticClass: "btn btn-link text-secondary m-0 p-0 order-5",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.removeSkill(_vm.item.id)
              },
            },
          },
          [
            _c("i", {
              staticClass: "fas d-print-none fa-minus-circle pr-2",
              attrs: { title: "Delete Aspect" },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }