var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sheet" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col-sm-6 text-center order-md-2 text-md-right pb-2 pb-md-0",
        },
        [
          _c("img", {
            staticClass: "img-fluid fate-logo",
            attrs: { alt: "Fate Condensed", src: _vm.$parent.GetSheetImage() },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 d-flex flex-column flex-md-row order-md-1" },
        [
          _c("div", { staticClass: "form-group d-flex mr-md-3 col-md-8" }, [
            _c("label", { staticClass: "mt-2 mr-2" }, [_vm._v("Name")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "name",
                name: "name",
                placeholder: "Name",
              },
              domProps: { value: _vm.getVal("name") },
              on: {
                change: function ($event) {
                  return _vm.setVal("name", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group d-flex col-md-4" }, [
            _c("label", { staticClass: "mt-2 mr-2" }, [_vm._v("Pronoun")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "pronoun",
                name: "pronoun",
                placeholder: "Pronoun",
              },
              domProps: { value: _vm.getVal("pronoun") },
              on: {
                change: function ($event) {
                  return _vm.setVal("pronoun", $event.target.value)
                },
              },
            }),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass: "col-sm-6 col-md-7 fate-aspects px-0",
          staticStyle: { "border-right": "2px solid #3A5224" },
        },
        [
          _c("div", { staticClass: "fate-header", attrs: { for: "" } }, [
            _vm._v("Aspects"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "px-2" },
            _vm._l(_vm.aspects, function (aspect) {
              return _c(
                "div",
                { key: aspect.obj },
                [
                  _c("inputaspect", {
                    attrs: { aspect: aspect, showlabel: true },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "inputstuntextra",
            _vm._g(
              {
                attrs: {
                  item: "stunts",
                  rows: 30,
                  border: false,
                  header: "Stunts",
                },
              },
              _vm.$listeners
            )
          ),
          _vm._v(" "),
          _c("div", { staticClass: "fate-header mb-5 mb-sm-0" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("input", {
                staticClass: "refresh pl-md-3",
                attrs: {
                  type: "number",
                  id: "refresh",
                  name: "refresh",
                  placeholder: "3",
                },
                domProps: { value: _vm.getVal("refresh") },
                on: {
                  change: function ($event) {
                    return _vm.setVal("refresh", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "pt-0" }, [_vm._v("Refresh")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "50px" } }),
          _vm._v(" "),
          _c("div", { staticClass: "fate-header mb-5 mb-sm-0 d-flex" }, [
            _c("div", { staticClass: "mr-auto" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { "min-height": "50px" } },
              [
                _vm.vttEnabled
                  ? _c(
                      "span",
                      { staticClass: "dice fo20 font-weight-normal" },
                      [_vm._v("A")]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "pt-0" }, [_vm._v("Fate Points")]),
                _vm._v(" "),
                _c("inputfatepoints", {
                  attrs: { inputclass: "fatepoints", placeholder: "-" },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 col-md-5 fate-skills px-0 mt-3 mt-sm-0" },
        [
          _c("div", { staticClass: "fate-header col-12" }, [_vm._v("Vitals")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "px-2" },
            [
              _c(
                "div",
                { staticClass: "form-group text-center font-weight-bold" },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _vm._v("STRESS  "),
                    _vm.vttEnabled
                      ? _c(
                          "span",
                          { staticClass: "dice fo20 font-weight-normal" },
                          [_vm._v("D")]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-md-flex flex-row pb-2" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group font-weight-bold pr-2 pt-2 mr-auto",
                  },
                  [_vm._v("\n\t\t\t\t\t\tPHYSICAL\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  _vm._l(_vm.physicalstress, function (stress) {
                    return _c(
                      "div",
                      { key: stress.obj },
                      [
                        _c("inputstress", {
                          attrs: {
                            stress: stress,
                            stresstype: "Physical",
                            hidelabel: true,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-md-flex flex-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group font-weight-bold pr-3 pt-2 mr-auto",
                  },
                  [_vm._v("\n\t\t\t\t\t\tMENTAL\n\t\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  _vm._l(_vm.mentalstress, function (stress) {
                    return _c(
                      "div",
                      { key: stress.obj },
                      [
                        _c("inputstress", {
                          attrs: {
                            stress: stress,
                            stresstype: "Mental",
                            hidelabel: true,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group text-center font-weight-bold" },
                [
                  _c("div", { staticClass: "col-12" }, [
                    _vm._v("CONSEQUENCES "),
                    _vm.vttEnabled
                      ? _c(
                          "span",
                          { staticClass: "dice fo20 font-weight-normal" },
                          [_vm._v("D")]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.consequences, function (consequence) {
                return _c(
                  "div",
                  { key: consequence.obj },
                  [
                    _c("inputconsequence", {
                      attrs: { consequence: consequence },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "fate-header col-12" }, [_vm._v("Skills")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "px-2 skills" },
            [
              _c(
                "div",
                { staticClass: "small text-muted font-italic d-print-none" },
                [
                  _vm._v(
                    "Click to edit skill names. Bonus stress is still calculated from value of physique/will slots even if you rename them."
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.skills, function (skill) {
                return _c(
                  "div",
                  { key: skill.obj, staticClass: "py-1" },
                  [_c("inputskill", { attrs: { item: skill } })],
                  1
                )
              }),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }