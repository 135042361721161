var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        _vm.currentCharacterSheet,
        _vm._g(
          {
            ref: "charactersheet",
            tag: "component",
            attrs: { character: _vm.character, isOwner: _vm.isOwner },
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }