var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "pager py-2 w-100 text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary btn-sm",
            attrs: { disabled: _vm.firstpage },
            on: {
              click: function ($event) {
                return _vm.getPage("first")
              },
            },
          },
          [_vm._v("First Page ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary btn-sm pager-prev",
            attrs: { disabled: _vm.firstpage },
            on: {
              click: function ($event) {
                return _vm.getPage("prev")
              },
            },
          },
          [_vm._v("<< Prev Page ")]
        ),
        _vm._v(" "),
        !_vm.more
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-sm",
                attrs: { disabled: !_vm.more && _vm.lastpage },
                on: {
                  click: function ($event) {
                    return _vm.getPage("next")
                  },
                },
              },
              [_vm._v("Next Page >>")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.more
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-sm",
                on: {
                  click: function ($event) {
                    return _vm.getPage("next")
                  },
                },
              },
              [_vm._v("Get More")]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }