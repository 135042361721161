var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pl-1 ml-1",
      class: _vm.getColor(_vm.aspect),
      attrs: { id: "aspect-" + _vm.commonSvc.GetId(_vm.aspect.id) },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "span",
            {
              staticClass: "dice fo20",
              on: {
                click: function ($event) {
                  return _vm.sendToVTT()
                },
              },
            },
            [_vm._v("C")]
          ),
          _vm._v(" "),
          _c("em", {
            domProps: { innerHTML: _vm._s(_vm.getLabel(_vm.aspect)) },
          }),
          _c("editableinput", {
            staticClass: "font-weight-bold pr-1 mr-auto",
            attrs: { object: _vm.aspect, item: "name" },
          }),
          _vm._v(" "),
          _c("invoke", {
            staticClass: "mx-2",
            attrs: { invokes: _vm.aspect.invokes },
          }),
          _vm._v(" "),
          !_vm.isCharacter
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link p-0 m-0",
                  attrs: { type: "button", title: "Remove aspect" },
                  on: {
                    click: function ($event) {
                      return _vm.removeAspect()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-trash-alt fa-xs" })]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }