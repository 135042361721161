var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mt-2" }, [
    _c(
      "div",
      { staticClass: "d-md-flex" },
      [
        _c(
          "h1",
          { staticClass: "mr-auto justify-content-center d-none d-md-block" },
          [_vm._v("Character Sheets")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mr-auto d-md-none" }),
        _vm._v(" "),
        _c("search", { staticClass: "mt-0 mt-md-1 mb-1" }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-column flex-md-row flex-wrap justify-content-between",
      },
      _vm._l(_vm.sheets, function (sheet) {
        return _c(
          "div",
          { key: sheet.id, staticClass: "card m-1 col col-sm-12 col-md-2 p-0" },
          [
            _c(
              "a",
              {
                attrs: {
                  href: "charactersheet/" + sheet.slug,
                  "data-id": sheet.id,
                  role: "button",
                },
              },
              [
                _c("img", {
                  staticClass: "card-img-top img-thumbnail sheet-images",
                  attrs: {
                    src: "/static/sheets/" + sheet.slug + "/logo.png",
                    alt: sheet.displayname + " Logo",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "h5",
                { staticClass: "card-title charactersheet-name mb-auto" },
                [_vm._v(_vm._s(sheet.name))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-success w-100",
                  attrs: {
                    href: "charactersheet/" + sheet.slug,
                    "data-id": sheet.id,
                    role: "button",
                  },
                },
                [_vm._v("Create "), _c("i", { staticClass: "fa fa-user" })]
              ),
              _vm._v(" "),
              sheet.customizable
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-success mt-1 w-100",
                      attrs: {
                        href: "charactersheet/" + sheet.slug + "-custom",
                        "data-id": sheet.id,
                        role: "button",
                      },
                    },
                    [
                      _vm._v("Customize "),
                      _c("i", { staticClass: "fas fa-user-cog" }),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "card-footer text-muted small",
              domProps: { innerHTML: _vm._s(sheet.description) },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }