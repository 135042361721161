var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-2" },
    [
      _c("loading", { attrs: { loading: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", [
            _c("div", { staticClass: "d-flex flex-column flex-sm-row" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("h3", { staticClass: "mr-auto" }, [
                _vm._v(_vm._s(_vm.campaign.name) + " — Campaign"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex p-1" }, [
                _c(
                  "a",
                  {
                    staticClass: "mr-auto",
                    attrs: {
                      href:
                        "/campaign-summary/" +
                        _vm.commonSvc.GetId(_vm.campaign.id) +
                        "/" +
                        _vm.campaign.slug,
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v("\n          Public Campaign\n          "),
                    _c("i", { staticClass: "fas fa-external-link-alt" }),
                  ]
                ),
                _vm._v(" "),
                !_vm.showimportant
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-link d-sm-block d-md-none p-0 m-0",
                        attrs: {
                          title: "Show Important Things",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showimportant = true
                          },
                        },
                      },
                      [
                        _vm._v("\n          Show Important "),
                        _c("i", { staticClass: "fas fa-angle-double-left" }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showimportant
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-link d-sm-block d-md-none m-0 p-0 pl-1",
                        attrs: {
                          title: "Hide Important Things",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showimportant = false
                          },
                        },
                      },
                      [
                        _vm._v("\n          Hide Important "),
                        _c("i", { staticClass: "fas fa-angle-double-right" }),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            !_vm.isNewCampaign
              ? _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "order-2 order-md-1 px-1 col-12",
                      class: {
                        "col-md-7": _vm.showimportant,
                        "col-lg-8": _vm.showimportant,
                      },
                      attrs: { id: "logs" },
                    },
                    [
                      _c("div", { staticClass: "header d-flex" }, [
                        _c("span", { staticClass: "h4" }, [
                          _vm._v("Session Log"),
                        ]),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _vm.sortDescending
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary btn-sm mr-1",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.sortDescending = false
                                  },
                                },
                              },
                              [_vm._m(2)]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.sortDescending
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary btn-sm mr-1",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.sortDescending = true
                                  },
                                },
                              },
                              [_vm._m(3)]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isFiltered,
                                expression: "isFiltered",
                              },
                            ],
                            staticClass: "btn btn-warning btn-sm mr-1",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.clearFilter()
                              },
                            },
                          },
                          [_vm._m(4)]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-sm",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addSession()
                              },
                            },
                          },
                          [_vm._m(5)]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "d-md-none d-lg-none d-xl-none pt-1 ml-1",
                            on: {
                              click: function ($event) {
                                return _vm.jumpTo("#summary")
                              },
                            },
                          },
                          [_c("i", { staticClass: "fas fa-arrow-circle-up" })]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isLoading
                        ? _c("div", { staticClass: "p-5 h2" }, [
                            _vm._v("Loading sessions..."),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.getSortedSessions, function (session) {
                        return _c(
                          "div",
                          {
                            key: session.id,
                            staticClass: "mt-1",
                            class: { mark: _vm.currentSession === session.id },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "px-1 bg-light",
                                attrs: {
                                  id:
                                    "editor-" + _vm.commonSvc.GetId(session.id),
                                },
                              },
                              [
                                _c("div", [
                                  _vm.currentSession !== session.id
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "badge badge-secondary",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getNiceDate(session.date)
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentSession === session.id
                                    ? _c(
                                        "span",
                                        { staticClass: "input-group-sm" },
                                        [
                                          _c("datetime", {
                                            attrs: { type: "datetime" },
                                            on: {
                                              close: function ($event) {
                                                _vm.jumpTo(
                                                  "#editor-" +
                                                    _vm.commonSvc.GetId(
                                                      session.id
                                                    )
                                                )
                                              },
                                            },
                                            model: {
                                              value: session.date,
                                              callback: function ($$v) {
                                                _vm.$set(session, "date", $$v)
                                              },
                                              expression: "session.date",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "d-flex" }, [
                                  _c(
                                    "span",
                                    { staticClass: "mt-2 mr-2 cursor" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: session.public,
                                            expression: "session.public",
                                          },
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "public-" + session.id,
                                        },
                                        domProps: {
                                          checked: Array.isArray(session.public)
                                            ? _vm._i(session.public, null) > -1
                                            : session.public,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a = session.public,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      session,
                                                      "public",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      session,
                                                      "public",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(session, "public", $$c)
                                              }
                                            },
                                            function ($event) {
                                              return _vm.saveSession(session)
                                            },
                                          ],
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "cursor",
                                          staticStyle: { "font-weight": "400" },
                                          attrs: {
                                            for: "public-" + session.id,
                                          },
                                        },
                                        [_vm._v("public?")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.currentSession !== session.id
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mx-2 cursor",
                                          on: {
                                            click: function ($event) {
                                              return _vm.setCurrentSession(
                                                session.id,
                                                session.description
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-edit pt-2 mt-1",
                                          }),
                                          _vm._v(" edit"),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentSession === session.id
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "mx-2 cursor",
                                          on: {
                                            click: function ($event) {
                                              _vm.setCurrentSession("", "")
                                              _vm.saveSession(session)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-save pt-2 mt-1",
                                          }),
                                          _vm._v(" done"),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mx-2 mr-auto cursor",
                                      attrs: {
                                        "data-id": session.id,
                                        "data-toggle": "modal",
                                        "data-target":
                                          "#modalDeleteSessionConfirm",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-trash pt-2 mt-1",
                                      }),
                                      _vm._v(" delete"),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "cursor",
                                      on: {
                                        click: function ($event) {
                                          return _vm.jumpTo("#logs")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-arrow-circle-up mt-1 pt-2",
                                      }),
                                      _vm._v(" scroll up"),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.currentSession === session.id
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: session.description,
                                      expression: "session.description",
                                    },
                                  ],
                                  staticClass:
                                    "sessionLog form-control mb-2 bg-light",
                                  attrs: {
                                    placeholder: "Session Information...",
                                  },
                                  domProps: { value: session.description },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.setOldSessionText($event)
                                    },
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          session,
                                          "description",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.setCurrentSessionText($event)
                                      },
                                    ],
                                    change: function ($event) {
                                      return _vm.parseSession($event, session)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card" },
                              [
                                _vm.currentSession === session.id
                                  ? _c("VueShowdown", {
                                      staticClass: "card-body",
                                      attrs: {
                                        extensions: ["fcsCampaign"],
                                        markdown: _vm.currentSessionText,
                                        options: {
                                          emoji: false,
                                          openLinksInNewWindow: true,
                                          parseImgDimensions: true,
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.currentSession !== session.id
                                  ? _c("VueShowdown", {
                                      staticClass: "card-body",
                                      attrs: {
                                        extensions: ["fcsCampaign"],
                                        markdown: session.description,
                                        options: {
                                          emoji: false,
                                          openLinksInNewWindow: true,
                                          parseImgDimensions: true,
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.showimportant
                    ? _c(
                        "div",
                        {
                          staticClass: "order-1 order-md-2 d-none d-md-block",
                          attrs: { id: "summary" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-link",
                              attrs: {
                                title: "Show Important Things",
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showimportant = true
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-angle-double-left",
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showimportant
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "order-1 order-md-2 px-1 col-12 col-md-5 col-lg-4",
                          attrs: { id: "summary" },
                        },
                        [
                          _c("div", { staticClass: "d-flex header" }, [
                            _c("h4", { staticClass: "mr-auto" }, [
                              _vm._v(
                                "\n            Important Things               \n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "d-md-none mt-1",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpTo("#logs")
                                  },
                                },
                              },
                              [
                                _vm._v("scroll down "),
                                _c("i", {
                                  staticClass: "fas fa-arrow-circle-down",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-link d-none d-md-block m-0 p-0 pl-1",
                                attrs: {
                                  title: "Hide Important Things",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.showimportant = false
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-angle-double-right",
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", {}, [
                            _vm._m(6),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(_vm.sortedAlphaSessions, function (thing) {
                                return _c(
                                  "li",
                                  { key: thing.id, staticClass: "d-flex" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-self-center pr-1",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-filter pr-1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.filterBy(thing.thing)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge badge-secondary mt-0",
                                          },
                                          [
                                            _vm._v(
                                              "x" +
                                                _vm._s(thing.sessionids.length)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-link p-0 text-danger text-left",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyThingToClipboard(
                                              thing.thing
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.niceThingDisplay(thing.thing)
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("small", {
                                      class: {
                                        mark: _vm.niceDescription(
                                          thing.description
                                        ),
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.niceDescription(thing.description)
                                        ),
                                      },
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm._m(7),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.sortedAlphaCharacters,
                                function (thing) {
                                  return _c(
                                    "li",
                                    { key: thing.id, staticClass: "d-flex" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-self-center pr-1",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-filter pr-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.filterBy(thing.thing)
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-secondary mt-0",
                                            },
                                            [
                                              _vm._v(
                                                "x" +
                                                  _vm._s(
                                                    thing.sessionids.length
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-link p-0 text-success text-left",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyThingToClipboard(
                                                thing.thing
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.niceThingDisplay(thing.thing)
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("small", {
                                        class: {
                                          mark: _vm.niceDescription(
                                            thing.description
                                          ),
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.niceDescription(
                                              thing.description
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _vm._m(8),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.sortedAlphaFacePlaces,
                                function (thing) {
                                  return _c(
                                    "li",
                                    { key: thing.id, staticClass: "d-flex" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-self-center pr-1",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-filter pr-1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.filterBy(thing.thing)
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-secondary mt-0",
                                            },
                                            [
                                              _vm._v(
                                                "x" +
                                                  _vm._s(
                                                    thing.sessionids.length
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-link p-0 text-info text-left",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyThingToClipboard(
                                                thing.thing
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.niceThingDisplay(thing.thing)
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("small", {
                                        class: {
                                          mark: _vm.niceDescription(
                                            thing.description
                                          ),
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.niceDescription(
                                              thing.description
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _vm._m(9),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(_vm.sortedAlphaAspects, function (thing) {
                                return _c(
                                  "li",
                                  { key: thing.id, staticClass: "d-flex" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-self-center pr-1",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-filter pr-1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.filterBy(thing.thing)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge badge-secondary mt-0",
                                          },
                                          [
                                            _vm._v(
                                              "x" +
                                                _vm._s(thing.sessionids.length)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-link p-0 text-muted text-left",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyThingToClipboard(
                                              thing.thing
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.niceThingDisplay(thing.thing)
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("small", {
                                      class: {
                                        mark: _vm.niceDescription(
                                          thing.description
                                        ),
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.niceDescription(thing.description)
                                        ),
                                      },
                                    }),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2", attrs: { id: "accordion" } }, [
              _vm._m(10),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "collapse",
                  class: { show: _vm.isNewCampaign },
                  attrs: {
                    id: "metadata",
                    "aria-labelledby": "campaignProperties",
                    "data-parent": "#accordion",
                  },
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.campaign.name,
                            expression: "campaign.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "name",
                          name: "name",
                          placeholder: "Campaign name",
                        },
                        domProps: { value: _vm.campaign.name },
                        on: {
                          change: _vm.slugify,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.campaign, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "scale" } }, [
                        _vm._v("Scale"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.campaign.scale,
                              expression: "campaign.scale",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "scale", name: "scale" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.campaign,
                                "scale",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", [_vm._v("None")]),
                          _vm._v(" "),
                          _c("option", [_vm._v("Mundane")]),
                          _vm._v(" "),
                          _c("option", [_vm._v("Supernatural")]),
                          _vm._v(" "),
                          _c("option", [_vm._v("Otherworldly")]),
                          _vm._v(" "),
                          _c("option", [_vm._v("Legendary ")]),
                          _vm._v(" "),
                          _c("option", [_vm._v("Godlike")]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "description" } }, [
                        _vm._v("Description"),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.campaign.description,
                            expression: "campaign.description",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          value: "",
                          id: "description",
                          name: "description",
                          placeholder: "Campaign description...",
                        },
                        domProps: { value: _vm.campaign.description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.campaign,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "imageUrl" } }, [
                        _vm._v("Campaign Image Url"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.campaign.image_url,
                            expression: "campaign.image_url",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          value: "",
                          id: "imageUrl",
                          name: "imageUrl",
                          placeholder: "Image url",
                        },
                        domProps: { value: _vm.campaign.image_url },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.campaign,
                              "image_url",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.saveCampaign },
                      },
                      [_vm._v("Save Campaign")]
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "modalDeleteSessionConfirm",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "deleteLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-dialog", attrs: { role: "document" } },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _vm._m(11),
                      _vm._v(" "),
                      _vm._m(12),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger js-delete",
                            attrs: { type: "button", "data-dismiss": "modal" },
                            on: { click: _vm.deleteSession },
                          },
                          [_vm._v("Delete")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button", "data-dismiss": "modal" },
                          },
                          [_vm._v("Close")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-success d-print-none mb-1 mr-1",
        attrs: { role: "button", href: "/campaign" },
      },
      [
        _c("i", { staticClass: "fas fa-chevron-circle-left" }),
        _vm._v(" Campaign List\n      "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "pl-1 pt-1 mr-auto",
        attrs: {
          target: "_blank",
          href: "https://github.com/sheibeck/fcs/wiki/Campaigns",
        },
      },
      [_c("i", { staticClass: "fas fa-question-circle" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("i", { staticClass: "fas fa-sort-amount-down mt-1 mr-1" }),
      _c("span", { staticClass: "d-none d-md-block" }, [_vm._v("Sort")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("i", { staticClass: "fas fa-sort-amount-up mt-1 mr-1" }),
      _c("span", { staticClass: "d-none d-md-block" }, [_vm._v("Sort")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("i", { staticClass: "fas fa-times mt-1 mr-1" }),
      _vm._v(" "),
      _c("span", { staticClass: "d-none d-md-block" }, [
        _vm._v("Clear Filter"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("i", { staticClass: "fas fa-book mt-1 mr-1" }),
      _vm._v(" "),
      _c("span", { staticClass: "d-none d-md-block" }, [_vm._v("Add Session")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "text-danger" }, [_vm._v("!")]),
      _vm._v("Issues"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "text-success" }, [_vm._v("#")]),
      _vm._v("Characters"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "text-info" }, [_vm._v("@")]),
      _vm._v("Faces & Places"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("span", { staticClass: "text-muted" }, [_vm._v("~")]),
      _vm._v("Campaign Aspects"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header", attrs: { id: "campaignProperties" } },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-link",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#metadata",
              "aria-expanded": "true",
              "aria-controls": "metadata",
            },
          },
          [_vm._v("\n          Campaign Properties\n        ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "deleteLabel" } }, [
        _vm._v("Confirm Character Delete"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", [_vm._v("Are you sure you want to delete this session?")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }