var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-draggable-resizable",
    {
      staticClass: "p-1 m-1 d-flex border bg-white zone draggable-item",
      style: "z-index:" + this.zone.zindex + ";",
      attrs: {
        id: "zone-" + _vm.commonSvc.GetId(_vm.zone.id),
        "drag-handle": ".zoneHandle",
        parent: true,
        "drag-cancel": ".cancelZoneDrag",
        x: _vm.zone.x,
        y: _vm.zone.y,
        w: _vm.zone.width,
        h: _vm.zone.height,
      },
      on: { dragging: _vm.onDrag, resizing: _vm.onResize },
    },
    [
      _vm.zone.backgroundImage && !_vm.zoneImageEdit
        ? _c("img", {
            staticClass: "img-fluid zone-image",
            attrs: { src: _vm.zone.backgroundImage },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("i", {
        staticClass:
          "fas fa-expand-arrows-alt p-1 mr-1 bg-dark text-white zoneHandle",
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mr-auto cancelZoneDrag w-100" }, [
        _vm.zoneImageEdit
          ? _c("div", { staticClass: "m-2 p-2 border bg-light" }, [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("Zone Image Url"),
              ]),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.zone.backgroundImage,
                      expression: "zone.backgroundImage",
                    },
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.zone.backgroundImage },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.zone, "backgroundImage", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "input-group-append",
                    staticStyle: { height: "38px" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "input-group-text",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleZoneImageEdit()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-check-circle text-success",
                        }),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href:
                        "https://www.google.com/search?safe=strict&tbm=isch&q=" +
                        this.zone.name,
                      title: "Search for image",
                    },
                  },
                  [_vm._v("Search for image")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.zoneImageEdit
          ? _c(
              "header",
              [
                !_vm.editing
                  ? _c(
                      "label",
                      {
                        staticStyle: { "vertical-align": "top" },
                        attrs: { title: "Click to edit" },
                        on: {
                          click: function ($event) {
                            _vm.editing = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.zone.name.toUpperCase()))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.editing
                  ? _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.zone.name,
                            expression: "zone.name",
                          },
                        ],
                        staticClass: "form-control-sm",
                        domProps: { value: _vm.zone.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.zone, "name", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "input-group-text",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.editing = false
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-check-circle text-success",
                            }),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.zone.aspects.length
                  ? _c("span", { staticStyle: { "vertical-align": "top" } }, [
                      _vm._v("— "),
                      _c("em", [_vm._v("Aspects")]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.zone.aspects, function (aspect) {
                  return _c("zoneaspect", {
                    key: aspect.id,
                    attrs: { aspect: aspect, location: "zone" },
                  })
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.zoneImageEdit
          ? _c(
              "div",
              {
                staticClass: "border",
                staticStyle: {
                  "min-height": "150px",
                  height: "94%",
                  width: "100%",
                },
                attrs: {
                  id: "drag-container-" + _vm.commonSvc.GetId(_vm.zone.id),
                },
              },
              _vm._l(_vm.zone.sceneobjects, function (item) {
                return _c("sceneobject", {
                  key: item.id,
                  attrs: { objectdata: item },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column bg-light pl-1" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0",
              attrs: { type: "button", title: "Add Zone Aspect" },
              on: {
                click: function ($event) {
                  return _vm.addZoneObject("aspect")
                },
              },
            },
            [_c("i", { staticClass: "fas fa-sticky-note" })]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "btn btn-link p-0",
              attrs: {
                id: "add-character-" + this.zone.id,
                type: "button",
                variant: "link",
                title: "Add Character",
              },
            },
            [_c("i", { staticClass: "fas fa-user-circle" })]
          ),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              ref: "popoverCharacter",
              attrs: {
                target: "add-character-" + this.zone.id,
                triggers: "click blur",
              },
              on: {
                shown: function ($event) {
                  return _vm.$refs.characterAutocomplete.$refs.input.focus()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Add Character")]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("autocomplete", {
                ref: "characterAutocomplete",
                attrs: {
                  search: _vm.searchCharacters,
                  "debounce-time": 500,
                  placeholder: "Search Characters",
                  "aria-label": "Search Characters",
                  "get-result-value": _vm.getCharacterResultValue,
                },
                on: { submit: _vm.selectCharacterResult },
                scopedSlots: _vm._u([
                  {
                    key: "result",
                    fn: function (ref) {
                      var result = ref.result
                      var props = ref.props
                      return [
                        _c("li", _vm._b({}, "li", props, false), [
                          _c("div", { staticClass: "p-0 m-0 h6" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(result.name) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "small p-0 m-0" }, [
                            result.aspects
                              ? _c("div", [
                                  result.aspects.highconcept
                                    ? _c("div", [
                                        _c(
                                          "label",
                                          { staticClass: "p-0 m-0" },
                                          [_vm._v("HC:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              result.aspects.highconcept || ""
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  result.aspects.trouble
                                    ? _c("div", [
                                        _c(
                                          "label",
                                          { staticClass: "p-0 m-0" },
                                          [_vm._v("T:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(result.aspects.trouble || "")
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [
                              _c("label", { staticClass: "p-0 m-0" }, [
                                _vm._v("Sheet:"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    result.related_id.replace(
                                      "CHARACTERSHEET|",
                                      ""
                                    )
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mt-1 ml-2 small" }, [
                _vm._v(
                  "\n        Searches your characters and the character's of other players in this scene. You can search by "
                ),
                _c("em", [_vm._v("name, aspect, character sheet type")]),
                _vm._v(".\n      "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "btn btn-link p-0",
              attrs: {
                id: "add-adversary-" + this.zone.id,
                type: "button",
                variant: "link",
                title: "Add Adversary",
              },
            },
            [_c("i", { staticClass: "fas fa-theater-masks" })]
          ),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              ref: "popoverAdversary",
              attrs: {
                target: "add-adversary-" + this.zone.id,
                triggers: "click blur",
              },
              on: {
                shown: function ($event) {
                  return _vm.$refs.adversaryAutocomplete.$refs.input.focus()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v("Add Adversary")]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("autocomplete", {
                ref: "adversaryAutocomplete",
                attrs: {
                  search: _vm.searchAdversaries,
                  "debounce-time": 500,
                  placeholder: "Search Adversaries",
                  "aria-label": "Search Adversaries",
                  "get-result-value": _vm.getAdversaryResultValue,
                },
                on: { submit: _vm.selectAdversaryResult },
                scopedSlots: _vm._u([
                  {
                    key: "result",
                    fn: function (ref) {
                      var result = ref.result
                      var props = ref.props
                      return [
                        _c("li", _vm._b({}, "li", props, false), [
                          _c("div", { staticClass: "p-0 m-0 h6" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(result.name) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "small p-0 m-0" }, [
                            result.aspects
                              ? _c("div", [
                                  result.aspects.high_concept
                                    ? _c("div", [
                                        _c(
                                          "label",
                                          { staticClass: "p-0 m-0" },
                                          [_vm._v("HC:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              result.aspects.high_concept || ""
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  result.aspects.trouble
                                    ? _c("div", [
                                        _c(
                                          "label",
                                          { staticClass: "p-0 m-0" },
                                          [_vm._v("T:")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(result.aspects.trouble || "")
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [
                              _c("label", { staticClass: "p-0 m-0" }, [
                                _vm._v("Type:"),
                              ]),
                              _vm._v(" " + _vm._s(result.type || "Unknown")),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("label", { staticClass: "p-0 m-0" }, [
                                _vm._v("System:"),
                              ]),
                              _vm._v(" " + _vm._s(result.system || "Unknown")),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("label", { staticClass: "p-0 m-0" }, [
                                _vm._v("Genre:"),
                              ]),
                              _vm._v(" " + _vm._s(result.genre || "Unknown")),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mt-1 ml-2" }, [
                _c("input", {
                  ref: "adversarySearchMine",
                  staticClass: "mr-1",
                  attrs: { type: "checkbox" },
                }),
                _vm._v("Search only my adversaries?\n        "),
                _c("div", { staticClass: "small" }, [
                  _vm._v("You can search by "),
                  _c("em", [_vm._v("name, aspect, type, system, or genre")]),
                  _vm._v("."),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "btn btn-link p-0",
              attrs: {
                id: "add-npc-" + this.zone.id,
                type: "button",
                variant: "link",
                title: "Add NPC",
              },
              on: {
                click: function ($event) {
                  return _vm.addNPC()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-users" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0",
              attrs: { type: "button", title: "Edit zone image" },
              on: {
                click: function ($event) {
                  return _vm.toggleZoneImageEdit()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-image" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0 mt-auto",
              attrs: { type: "button", title: "Move zone forward" },
              on: {
                click: function ($event) {
                  return _vm.moveForward()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-chevron-circle-up" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0",
              attrs: { type: "button", title: "Move zone backward" },
              on: {
                click: function ($event) {
                  return _vm.moveBackward()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-chevron-circle-down" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link p-0",
              attrs: { type: "button", title: "Delete zone" },
              on: {
                click: function ($event) {
                  return _vm.removeZone()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-trash-alt" })]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }