var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "nav",
        { staticClass: "navbar navbar-expand-lg navbar-dark bg-dark" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "navbarSupportedContent" },
            },
            [
              _c("ul", { staticClass: "navbar-nav mr-auto" }, [
                _vm.isAuthenticated
                  ? _c(
                      "li",
                      {
                        staticClass: "nav-item dropdown",
                        class: {
                          active:
                            _vm.isActive("character") ||
                            _vm.isActive("campaign"),
                        },
                      },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu bg-dark",
                            attrs: { "aria-labelledby": "navbarDropdown" },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                class: { active: _vm.isActive("character") },
                                attrs: { href: "/character" },
                              },
                              [
                                _c("i", { staticClass: "fas fa-user" }),
                                _vm._v(" My Characters"),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                class: { active: _vm.isActive("campaign") },
                                attrs: { href: "/campaign" },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-globe-americas",
                                }),
                                _vm._v(" My Campaigns"),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                class: { active: _vm.isActive("scene") },
                                attrs: { href: "/scene" },
                              },
                              [
                                _c("i", { staticClass: "fas fa-book-open" }),
                                _vm._v(" My Scenes"),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    ref: "el",
                    staticClass: "nav-item",
                    class: { active: _vm.isActive("charactersheet") },
                  },
                  [_vm._m(3)]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    ref: "el",
                    staticClass: "nav-item",
                    class: { active: _vm.isActive("adversary") },
                  },
                  [_vm._m(4)]
                ),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _vm._m(6),
                _vm._v(" "),
                !_vm.HasSubscription
                  ? _c("li", { ref: "el", staticClass: "nav-item" }, [
                      !_vm.isAuthenticated
                        ? _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: "/register" },
                            },
                            [
                              _c("span", { staticClass: "dice" }, [
                                _vm._v("C"),
                              ]),
                              _vm._v(" Register"),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAuthenticated
                        ? _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              attrs: { href: "/account" },
                            },
                            [
                              _c("span", { staticClass: "dice" }, [
                                _vm._v("C"),
                              ]),
                              _vm._v(" Subscribe"),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              !_vm.isAuthenticated
                ? _c("div", { staticClass: "navbar-nav" }, [_vm._m(7)])
                : _vm._e(),
              _vm._v(" "),
              _vm.isAuthenticated
                ? _c("div", { staticClass: "navbar-nav" }, [
                    _vm._m(8),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: { href: "#" },
                        on: { click: _vm.logout },
                      },
                      [
                        _c("i", { staticClass: "fas fa-sign-out-alt" }),
                        _vm._v(" Sign out \n            "),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "m-0", attrs: { id: "alert_placeholder" } }),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _vm._m(9),
      _vm._v(" "),
      _c("diceroller"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "navbar-brand", attrs: { href: "/" } }, [
      _c("img", { attrs: { src: "/static/img/logo.png", alt: "Logo" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          href: "#",
          id: "myStuffDropdown",
          role: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [
        _c("span", { staticClass: "dice" }, [_vm._v("C")]),
        _vm._v("My Stuff\n               "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "nav-link", attrs: { href: "/charactersheet" } },
      [
        _c("span", { staticClass: "dice" }, [_vm._v("D")]),
        _vm._v("Character Sheets"),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "nav-link", attrs: { href: "/adversary" } }, [
      _c("span", { staticClass: "dice" }, [_vm._v("A")]),
      _vm._v("Adversaries"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item dropdown" }, [
      _c(
        "a",
        {
          staticClass: "nav-link dropdown-toggle",
          attrs: {
            href: "#",
            id: "navbarDropdown",
            role: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [
          _c("i", { staticClass: "fas fa-dungeon" }),
          _vm._v(" Tools\n               "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu bg-dark",
          attrs: { "aria-labelledby": "navbarDropdown" },
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { target: "_blank", href: "https://fate-srd.com/" },
            },
            [
              _c("span", { staticClass: "dice" }, [_vm._v("+")]),
              _vm._v(" Fate SRD"),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                id: "play-fate",
                target: "_blank",
                href: "https://app.roll20.net/lfg/search/?playingstructured=fate",
              },
            },
            [
              _c("img", {
                staticClass: "roll-20",
                attrs: {
                  src: "/static/img/roll20logo.png",
                  alt: "Roll20 logo",
                },
              }),
              _vm._v(" Roll20.net"),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { target: "_blank", href: "https://www.rpgsolo.com/" },
            },
            [
              _c("span", { staticClass: "dice" }, [_vm._v("+")]),
              _vm._v(" RPG Solo"),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-divider" }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                id: "roll-fate",
                target: "#",
                href: "#",
                "data-toggle": "modal",
                "data-target": "#modalDiceRoller",
              },
            },
            [
              _c("span", { staticClass: "dice" }, [_vm._v("+")]),
              _vm._v(" Fate Dice Roller"),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item dropdown" }, [
      _c(
        "a",
        {
          staticClass: "nav-link dropdown-toggle",
          attrs: {
            href: "#",
            id: "navbarDropdown",
            role: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [
          _c("span", { staticClass: "dice" }, [_vm._v("O")]),
          _vm._v(" Support\n              "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu bg-dark",
          attrs: { "aria-labelledby": "navbarDropdown" },
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                target: "_blank",
                href: "https://github.com/sheibeck/fcs/wiki/Fate-Character-Sheet",
              },
            },
            [
              _c("i", { staticClass: "fas fa-hat-wizard" }),
              _vm._v(" Help & FAQ"),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                target: "_blank",
                href: "https://github.com/sheibeck/fcs/issues",
              },
            },
            [_c("i", { staticClass: "fas fa-dragon" }), _vm._v(" Report Issue")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                target: "_blank",
                href: "https://sterlingheibeck.wordpress.com/category/fate-character-sheet/",
              },
            },
            [_c("i", { staticClass: "fas fa-scroll" }), _vm._v(" Blog")]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "nav-link", attrs: { href: "/login", type: "button" } },
      [
        _c("i", { staticClass: "fas fa-sign-in-alt" }),
        _vm._v(" Sign in\n            "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "nav-link", attrs: { href: "/account" } }, [
      _c("i", { staticClass: "fas fa-user-cog" }),
      _vm._v(" Account \n            "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("div", [
            _c("span", { staticClass: "dice" }, [_vm._v("C")]),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-md-inline" }, [
              _vm._v("Built by"),
            ]),
            _vm._v(" Darktier Studios, LLC.            \n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-none d-sm-block" }, [
            _c("span", { staticClass: "dice" }, [_vm._v("A")]),
            _vm._v(" Powered by Fate\n        "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pt-2" }, [
            _c(
              "a",
              {
                staticClass: "iubenda-black iubenda-embed",
                attrs: {
                  href: "https://www.iubenda.com/privacy-policy/23267044",
                  title: "Privacy Policy ",
                },
              },
              [_vm._v("Privacy Policy")]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }