var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group d-flex flex-column" }, [
    _vm.customlabel
      ? _c("div", { staticClass: "d-flex align-items-center" }, [
          _vm.vttEnabled
            ? _c(
                "span",
                {
                  staticClass: "dice fo20 pt-1 pr-1",
                  on: {
                    click: function ($event) {
                      return _vm.sendToVTT()
                    },
                  },
                },
                [_vm._v("C")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.editlock
            ? _c("input", {
                staticClass: "w-75 mr-auto inputlabel inputConsequenceLabel",
                attrs: {
                  type: "text",
                  placeholder: _vm.consequence.placeholder,
                },
                domProps: {
                  value: _vm.$parent.getVal("" + _vm.consequence.label),
                },
                on: {
                  change: function ($event) {
                    return _vm.$parent.setVal(
                      "" + _vm.consequence.label,
                      $event.target.value
                    )
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.editlock
            ? _c("label", { staticClass: "inputConsequenceLabel" }, [
                _vm._v(_vm._s(_vm.getLabel)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.removable && !_vm.editlock
            ? _c(
                "button",
                {
                  staticClass: "btn btn-link text-secondary m-0 p-0",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.removeConsequence(_vm.consequence.id)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fas d-print-none fa-minus-circle",
                    attrs: { title: "Delete Consequence" },
                  }),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex align-items-center" }, [
      !_vm.customlabel && _vm.vttEnabled
        ? _c(
            "span",
            {
              staticClass: "dice fo20 pt-1 pr-1",
              on: {
                click: function ($event) {
                  return _vm.sendToVTT()
                },
              },
            },
            [_vm._v("C")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.customlabel && !_vm.editlock
        ? _c("input", {
            staticClass: "mr-auto inputlabel text-center",
            staticStyle: { width: "40px" },
            attrs: {
              type: "text",
              placeholder: _vm.consequence.valueplaceholder,
            },
            domProps: { value: _vm.$parent.getVal("" + _vm.consequence.value) },
            on: {
              change: function ($event) {
                return _vm.$parent.setVal(
                  "" + _vm.consequence.value,
                  $event.target.value
                )
              },
            },
          })
        : _c("label", { staticClass: "pr-3" }, [
            _vm._v(_vm._s(_vm.getLabelValue)),
          ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "text",
          id: _vm.consequence.obj,
          name: _vm.consequence.obj,
          placeholder: _vm.getPlaceHolder,
          disabled: !_vm.skillHasValue(),
        },
        domProps: { value: _vm.$parent.getVal(_vm.consequence.obj) },
        on: {
          change: function ($event) {
            return _vm.setVal(_vm.consequence.obj, $event.target.value)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }