var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-2" },
    [
      _vm.isAuthenticated
        ? _c(
            "div",
            { staticClass: "d-print-none mb-2 d-md-flex" },
            [_vm._m(0), _vm._v(" "), _c("search", {})],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("loading", { attrs: { loading: _vm.isLoading } }),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("div", [
            !_vm.hasCampaigns
              ? _c("div", [
                  _c("h2", [_vm._v("You have not created any campaigns.")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasCampaigns
              ? _c(
                  "div",
                  { staticClass: "card-columns" },
                  _vm._l(_vm.filteredCampaigns, function (item) {
                    return _c("div", { key: item.id, staticClass: "card" }, [
                      item.image_url
                        ? _c("img", {
                            staticClass: "card-img-top list-image",
                            attrs: {
                              src: item.image_url,
                              alt: "campaign image",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _c("h5", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("p", { staticClass: "card-text col" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getShortText(item.description)) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                href:
                                  "/campaign/" +
                                  _vm.commonSvc.GetId(item.id) +
                                  "/" +
                                  item.slug,
                                "data-id": item.id,
                              },
                            },
                            [
                              _vm._v("Play "),
                              _c("i", { staticClass: "fa fa-play-circle" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-secondary ml-1 mr-auto",
                              attrs: {
                                href:
                                  "/campaign-summary/" +
                                  _vm.commonSvc.GetId(item.id) +
                                  "/" +
                                  item.slug,
                              },
                              on: { click: _vm.shareUrl },
                            },
                            [
                              _vm._v("Share "),
                              _c("i", { staticClass: "fa fa-share-square" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              staticStyle: { color: "red" },
                              attrs: {
                                href: "#",
                                "data-id": item.id,
                                "data-toggle": "modal",
                                "data-target": "#modalDeleteConfirm",
                              },
                            },
                            [_c("i", { staticClass: "fa fa-trash" })]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-footer text-muted" }, [
                        _c(
                          "span",
                          {
                            staticClass: "badge badge-secondary",
                            staticStyle: { cursor: "pointer" },
                            attrs: { "data-search-text": item.scale },
                            on: { click: _vm.searchByTag },
                          },
                          [_vm._v(_vm._s(item.scale))]
                        ),
                        _vm._v("\n          @ "),
                        _c("span", {}, [
                          _vm._v(_vm._s(_vm.getNiceDate(item.date))),
                        ]),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("input", { staticClass: "hidden", attrs: { id: "copyUrl" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "modalDeleteConfirm",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "deleteLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", attrs: { role: "document" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger js-delete",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.deleteCampaign },
                    },
                    [_vm._v("Delete")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-success mr-auto mb-1 mb-md-0",
        attrs: { href: "/campaign/create" },
      },
      [
        _vm._v("Create a Campaign "),
        _c("i", { staticClass: "fa fa-globe-americas" }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title", attrs: { id: "deleteLabel" } }, [
        _vm._v("Confirm Campaign Delete"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", [_vm._v("Are you sure you want to delete this campaign?")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }