var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pl-1 ml-1 d-flex" },
    [
      _c("editableinput", {
        staticClass: "mr-2 font-weight-bold small",
        attrs: { object: _vm.stress, item: "name" },
      }),
      _vm._v(" "),
      _vm._l(_vm.stress.boxes, function (box) {
        return _c(
          "div",
          { key: box.id, staticClass: "d-flex" },
          [
            typeof box.label == "string"
              ? _c("editableinput", {
                  staticClass: "small",
                  attrs: { object: box, item: "label" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              staticClass: "mr-1 mt-1",
              attrs: { type: "checkbox" },
              domProps: { checked: box.used },
              on: {
                change: function ($event) {
                  return _vm.toggleStress($event, box.id)
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      !_vm.isCondition
        ? _c(
            "button",
            {
              staticClass: "btn btn-link p-0 m-0",
              attrs: { type: "button", title: "Add stress box" },
              on: {
                click: function ($event) {
                  return _vm.addStressBox()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-plus-square fa-xs" })]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isCondition && _vm.stress.boxes.length > 0
        ? _c(
            "button",
            {
              staticClass: "btn btn-link p-0 m-0",
              attrs: { type: "button", title: "Remove stress box" },
              on: {
                click: function ($event) {
                  return _vm.removeStressBox()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-minus-square fa-xs" })]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }